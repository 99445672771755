import { FunctionComponent, memo } from "react";
import { faCheck, faUser } from "@fortawesome/free-solid-svg-icons";

import { AdminNestingProjectData } from "../../service/api.dtos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateFormatter } from "../../components/DateFormatter";
import formatAsPercentage from "../../components/FormatAsPercentage";
import { useHistory } from "react-router";

const NestingReportsBody: FunctionComponent<{
  nestingReports: AdminNestingProjectData[];
}> = memo(({ nestingReports }) => {
  const history = useHistory();
  function accountClick(id: string) {
    return history.push({
      pathname: "/user-account",
      search: `?userId=${id}`,
    });
  }

  return (
    <tbody className="small">
      {nestingReports.map((nest) => (
        <tr key={nest.runId} className="theme-hover-row">
          <th scope="row">{nest?.userFirstName}</th>
          <th scope="row">{nest?.userSurname}</th>

          <td className="text-nowrap">{dateFormatter(nest?.createdDate)}</td>
          <td className="text-nowrap">
            {dateFormatter(nest?.workerReceivedDate)}
          </td>
          <td className="text-nowrap">{dateFormatter(nest?.startedDate)}</td>
          <td className="text-nowrap">
            {dateFormatter(nest?.userInformedDate)}
          </td>
          <td className="text-nowrap">{dateFormatter(nest?.failedDate)}</td>
          <td className="text-nowrap">{dateFormatter(nest?.finishedDate)}</td>

          <td className="text-nowrap">{formatAsPercentage(nest.efficiency)}</td>
          <td className="text-nowrap text-center">
            {nest.totalPartsNested && nest.totalPartsToNest ? (
              <>
                {nest.totalPartsNested} / {nest.totalPartsToNest}
              </>
            ) : (
              <div className="opacity-50 text-center">--</div>
            )}
          </td>
          <td className="text-nowrap text-center">
            {nest.sheetCount && nest.sheetUsedCount ? (
              <>
                {nest.sheetUsedCount} / {nest.sheetCount}
              </>
            ) : (
              <div className="opacity-50 text-center">--</div>
            )}
          </td>

          <td className="text-nowrap text-end">
            {nest.uniqueNests ? nest.uniqueNests : 0}
          </td>
          <td className="text-nowrap text-end">{nest.allocatedSeconds}s</td>
          <td className="text-nowrap text-center">
            {nest?.cancelled ? (
              <FontAwesomeIcon icon={faCheck} fixedWidth />
            ) : (
              <span className="opacity-50">--</span>
            )}
          </td>

          {/* Account button */}
          <td className="align-middle text-end">
            <button
              className="btn btn-sm btn-secondary float-end text-body py-0 border align-middle"
              onClick={() => accountClick(nest.createdById)}
            >
              <FontAwesomeIcon icon={faUser} fixedWidth />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  );
});
export default NestingReportsBody;
