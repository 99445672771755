import { AdminNestingProjectData, NestingReport } from "../../service/api.dtos";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableHeader, toggleHeaderSort } from "../../Sort";
import {
  faCircleNotch,
  faFileDownload,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchNestingReportCSV,
  fetchNestingReportJSON,
} from "./nestingReportsSlice";
import {
  getReports,
  getReportsCsvLoading,
  getReportsLoading,
} from "./nestingReports";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListHeader from "../../components/ListHeaders";
import LoadingOverlay from "../../components/LoadingOverlay";
import NestingReportsBody from "./NestingReportsBody";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { useHistory } from "react-router";
import { useNestingReportsHeaders } from "./useNestingReportsHeaders";
import { useQuery } from "../../utils";

const NestingReportsScreen: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const reports = useAppSelector(getReports);
  const initialNestingReportsSortHeaders = useNestingReportsHeaders();
  const reportsLoading = useAppSelector(getReportsLoading);
  const reportsCsvLoading = useAppSelector(getReportsCsvLoading);
  const [dateInputFrom, setDateInputFrom] = useState<string>(
    new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString(
      "fr-CA"
    )
  );

  const [dateInputTo, setDateInputTo] = useState<string>(
    new Date().toLocaleDateString("fr-CA")
  );

  const defaultDateTo = new Date().toLocaleDateString("fr-CA");
  const defaultDateFrom = new Date(
    new Date().setDate(new Date().getDate() - 7)
  ).toLocaleDateString("fr-CA");

  const [userId, setUserId] = useState<string>(query.get("id") ?? "");

  const [sortedNestingReports, setSortedNestingReports] = useState<
    AdminNestingProjectData[]
  >([]);

  const [tableHeaders, setTableHeaders] = useState<TableHeader[]>([
    ...initialNestingReportsSortHeaders,
  ]);

  const sortingColumn = useMemo(() => {
    const active = tableHeaders.find((header) => header.sort.active);

    return active;
  }, [tableHeaders]);

  const setHeaderSort = useCallback(
    (header: TableHeader) => {
      if (header.property.length > 0) {
        setTableHeaders(
          toggleHeaderSort(header, tableHeaders, reports, "runId")
        );
      }
    },
    [reports, tableHeaders]
  );

  useEffect(() => {
    typeof sortingColumn !== "undefined"
      ? setSortedNestingReports(
          sortingColumn.sort.sortedIndexes.map((index) => {
            return reports[index];
          })
        )
      : setSortedNestingReports(reports);
  }, [reports, sortingColumn]);

  const [cleared, setCleared] = useState(false);

  function clear() {
    setDateInputFrom(defaultDateFrom);
    setDateInputTo(defaultDateTo);
    setUserId("");

    setCleared(true);
    return history.push({
      search: "",
    });
  }

  const reportRef = React.useRef(true);

  const handleFetchReports = useCallback(() => {
    if (!dateInputFrom || !dateInputTo) return;

    dispatch(
      fetchNestingReportJSON({
        from: dateInputFrom,
        to: dateInputTo,
        userId: query.get("id") ?? userId,
      } as NestingReport)
    )
      .unwrap()
      .catch((e) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: e?.responseStatus?.message ?? "Oops?",
          })
        );
      });
  }, [dateInputFrom, dateInputTo, userId, dispatch, query]);

  useEffect(() => {
    if (cleared) {
      setCleared(false);
      handleFetchReports();
    }
  }, [cleared, handleFetchReports]);

  useEffect(() => {
    if (reportRef.current) handleFetchReports();
    reportRef.current = false;
  }, [handleFetchReports]);

  function handleCSVDownload(e) {
    e.preventDefault();

    dispatch(
      fetchNestingReportCSV({
        from: dateInputFrom,
        to: dateInputTo,
        userId: userId,
      } as NestingReport)
    );
  }

  useEffect(() => {
    let stringBuilder = userId ? `?userId=${encodeURIComponent(userId)} ` : "";

    return history.push({
      pathname: "/nesting-reports",
      search: stringBuilder,
    });
  }, [history, userId]);

  return (
    <section className="container-fluid py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          {/* Title */}
          <h4 className="text-nowrap">Nesting Reports</h4>
          <div className="rounded flex-fill mx-3 bg-secondary w-75 h-50 text-secondary">
            .
          </div>
          {/* Date Filter */}
          <div className="input-group input-group-sm">
            <>
              {/* User Id Input */}
              <input
                className="form-control"
                type="text"
                placeholder="User Id"
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
                style={{ minWidth: "330px" }}
              />
              {/* Date Input From */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputFrom(e.target.value)}
                value={dateInputFrom}
                max={new Date().toISOString().substring(0, 10)}
              />

              {/* Date Input To */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputTo(e.target.value)}
                value={dateInputTo}
                max={new Date().toISOString().substring(0, 10)}
              />
              {/* Reports amount */}
              <div
                className="input-group-text bg-dark"
                style={{ minWidth: "70px" }}
              >
                {reports?.length}
              </div>

              {/* Clear */}
              <button
                className="btn btn-secondary border bg-dark"
                type="button"
                onClick={clear}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>

              {/* Download */}
              <button
                title="Report CSV"
                type="button"
                className="btn btn-secondary border bg-dark"
                disabled={reportsCsvLoading === "pending"}
                onClick={handleCSVDownload}
              >
                {reportsCsvLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faFileDownload} fixedWidth />
                )}
              </button>

              {/* Search Button */}
              <button
                className="btn btn-secondary border bg-dark"
                id="search"
                onClick={handleFetchReports}
                disabled={reportsLoading === "pending"}
              >
                {reportsLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                )}
              </button>
            </>
          </div>
        </div>
        {/* Table */}
        <div className="card-body d-flex flex-grow-1 position-relative m-3 h-100">
          <div className="border rounded theme-overflow">
            {reportsLoading === "pending" && (
              <LoadingOverlay loading={"pending"} />
            )}
            {reportsLoading !== "pending" &&
              (reportsLoading === "failed" ||
                !sortedNestingReports?.length) && (
                <h4 className="text-center p-5">No results</h4>
              )}
            {reportsLoading !== "pending" &&
              reportsLoading !== "failed" &&
              sortedNestingReports?.length > 0 && (
                <div className="theme-overflow theme-overflow-table table-responsive   ">
                  <table
                    id="table-reports"
                    className="table table-dark table-hover mb-0"
                  >
                    <thead className="sticky-top border-bottom">
                      <tr>
                        {tableHeaders.map((header, index) => (
                          <ListHeader
                            key={index}
                            header={header}
                            toggleHeaderSort={setHeaderSort}
                            canSort={sortedNestingReports.length > 1}
                          />
                        ))}
                      </tr>
                    </thead>
                    <NestingReportsBody nestingReports={sortedNestingReports} />
                  </table>
                </div>
              )}
          </div>
        </div>
        {/*  */}
      </div>
    </section>
  );
};
export default NestingReportsScreen;
