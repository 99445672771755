import {
  ExtendPlanSuccessStatus,
  ExtentUsersPlan,
  FreeAccountById,
  FreeAccountSuccessStatus,
  GetUserProfileByEmailOrUserId,
  LockUserById,
  LockedSuccessStatus,
  MarkUserForDeletion,
  MaxFileSizeById,
  MaxFileSizeSuccessStatus,
  SetExportLimit,
  SetMaxImportLineCountPerPart,
  SetMaxUniqueNestsForReports,
  SetNestingSeconds,
  TrialAcceptOrDeny,
  UserFailures,
  UserProfileData,
} from "../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { JsonServiceClient } from "@servicestack/client";
import { Loading } from "./commonTypes";
import { RootState } from "./store";
import { fetchUsersFailures } from "./usersFailuresSlice";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);
export type UsersState = {
  addMinutesLoading: Loading;
  addMinutesUpdateStatus: ExtendPlanSuccessStatus;
  exportLimitLoading: Loading;
  freeAccountLoading: Loading;
  freeAccountStatus: FreeAccountSuccessStatus;
  loading: Loading;
  markForDeletionLoading: Loading;
  maxEntitiesCountLoading: Loading;
  maxFileSizeUploadUpdateStatus: MaxFileSizeSuccessStatus;
  maxFileUploadSizeLoading: Loading;
  nestingSecondsLoading: Loading;
  pauseAccountLoading: Loading;
  pauseAccountUpdateStatus: LockedSuccessStatus;
  reportMaxNestsLoading: Loading;
  searchQueryArg: GetUserProfileByEmailOrUserId;
  trialAcceptOrDenyLoading: Loading;
  userProfileData: UserProfileData;
};

const initialState: UsersState = {
  addMinutesLoading: "idle",
  addMinutesUpdateStatus: new ExtendPlanSuccessStatus(),
  exportLimitLoading: "idle",
  freeAccountLoading: "idle",
  freeAccountStatus: new FreeAccountSuccessStatus(),
  loading: "idle",
  markForDeletionLoading: "idle",
  maxEntitiesCountLoading: "idle",
  maxFileSizeUploadUpdateStatus: new MaxFileSizeSuccessStatus(),
  maxFileUploadSizeLoading: "idle",
  nestingSecondsLoading: "idle",
  pauseAccountLoading: "idle",
  pauseAccountUpdateStatus: {
    isSuccessful: true,
    lockedStatus: false,
  } as LockedSuccessStatus,
  reportMaxNestsLoading: "idle",
  searchQueryArg: new GetUserProfileByEmailOrUserId(),
  trialAcceptOrDenyLoading: "idle",
  userProfileData: new UserProfileData(),
};

export const getUser = createAsyncThunk(
  "user/getUser",
  async ({ email, userId }: GetUserProfileByEmailOrUserId, thunkAPI) => {
    return await client
      .get(new GetUserProfileByEmailOrUserId({ email, userId }))
      .then((user) => {
        thunkAPI.dispatch(
          fetchUsersFailures({
            userId: user.user.id,
          } as UserFailures)
        );

        return user;
      });
  }
);

export const setMaxImportLineCountPerPart = createAsyncThunk(
  "user/setMaxImportLineCountPerPart",
  async (
    { maxImportLineCount, userId }: SetMaxImportLineCountPerPart,
    thunkAPI
  ) => {
    return await client
      .put(new SetMaxImportLineCountPerPart({ maxImportLineCount, userId }))
      .then((data) => {
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return data;
      });
  }
);

export const addMinutes = createAsyncThunk(
  "user/addMinutes",
  async ({ userId, addMinutes }: ExtentUsersPlan, thunkAPI) => {
    return await client
      .post(new ExtentUsersPlan({ userId, addMinutes }))
      .then((status) => {
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      });
  }
);

export const maxFileUploadSize = createAsyncThunk(
  "user/maxFileUploadSize",
  async ({ userId, setValueTo }: MaxFileSizeById, thunkAPI) => {
    return await client
      .post(new MaxFileSizeById({ userId, setValueTo }))
      .then((status) => {
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      });
  }
);

export const pauseAccount = createAsyncThunk(
  "user/pauseAccount",
  async ({ setLockValueTo }: LockUserById, thunkAPI) => {
    //get users from state

    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;

    return await client
      .post(new LockUserById({ userId: id, setLockValueTo }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      });
  }
);

export const setFreeAccount = createAsyncThunk(
  "user/freeAccount",
  async ({ setValueTo, freeUntil }: FreeAccountById, thunkAPI) => {
    //get users from state
    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;

    return await client
      .post(new FreeAccountById({ userId: id, setValueTo, freeUntil }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      });
  }
);

export const setMarkForDeletion = createAsyncThunk(
  "user/setMarkForDeletion",
  async ({ deleteAfter }: MarkUserForDeletion, thunkAPI) => {
    //get users from state
    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;

    return await client
      .post(
        new MarkUserForDeletion({
          userId: id,
          deleteAfter,
        })
      )
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setNestingSeconds = createAsyncThunk(
  "user/SetNestingSeconds",
  async ({ seconds }: Partial<SetNestingSeconds>, thunkAPI) => {
    //get users from state
    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;

    return await client
      .put(new SetNestingSeconds({ userId: id, seconds }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setMaxUniqueNestsForReports = createAsyncThunk(
  "user/setMaxUniqueNestsForReports ",
  async (
    { maxUniqueNests }: Partial<SetMaxUniqueNestsForReports>,
    thunkAPI
  ) => {
    //get users from state
    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;

    return await client
      .put(new SetMaxUniqueNestsForReports({ userId: id, maxUniqueNests }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const setExportLimit = createAsyncThunk(
  "user/SetExportLimit",
  async ({ limit }: Partial<SetExportLimit>, thunkAPI) => {
    //get users from state
    const {
      userState: {
        userProfileData: {
          user: { id },
        },
      },
    } = thunkAPI.getState() as RootState;
    return await client
      .put(new SetExportLimit({ userId: id, limit }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const trialAcceptOrDeny = createAsyncThunk(
  "user/trialAcceptOrDeny",
  async (
    { userId, teamId, action, ticket }: Partial<TrialAcceptOrDeny>,
    thunkAPI
  ) => {
    return await client
      .post(new TrialAcceptOrDeny({ userId, teamId, action, ticket }))
      .then((status) => {
        //Fetch user again
        const {
          userState: { searchQueryArg },
        } = thunkAPI.getState() as RootState;

        thunkAPI.dispatch(getUser(searchQueryArg));

        return status;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const searchUserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    disposeUserState: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userProfileData = action.payload as UserProfileData;
        state.searchQueryArg = action.meta.arg;
        state.loading = "succeeded";
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = "failed";
        state.userProfileData = new UserProfileData();
      })
      //Add minutes
      .addCase(addMinutes.pending, (state) => {
        state.addMinutesLoading = "pending";
      })
      .addCase(addMinutes.fulfilled, (state, action) => {
        state.addMinutesUpdateStatus = action.payload;

        state.addMinutesLoading = "succeeded";
      })
      .addCase(addMinutes.rejected, (state, action) => {
        state.addMinutesUpdateStatus =
          action.payload as ExtendPlanSuccessStatus;
        state.addMinutesLoading = "failed";
      })
      // Max file upload size
      .addCase(maxFileUploadSize.pending, (state) => {
        state.maxFileUploadSizeLoading = "pending";
      })
      .addCase(maxFileUploadSize.fulfilled, (state, action) => {
        state.maxFileSizeUploadUpdateStatus = action.payload;

        state.maxFileUploadSizeLoading = "succeeded";
      })
      .addCase(maxFileUploadSize.rejected, (state, action) => {
        state.maxFileSizeUploadUpdateStatus =
          action.payload as MaxFileSizeSuccessStatus;
        state.maxFileUploadSizeLoading = "failed";
      })
      // Max entities count
      .addCase(setMaxImportLineCountPerPart.pending, (state) => {
        state.maxEntitiesCountLoading = "pending";
      })
      .addCase(setMaxImportLineCountPerPart.fulfilled, (state, action) => {
        state.maxEntitiesCountLoading = "succeeded";
      })
      .addCase(setMaxImportLineCountPerPart.rejected, (state, action) => {
        state.maxEntitiesCountLoading = "failed";
      })

      // Pause / Lock account
      .addCase(pauseAccount.pending, (state) => {
        state.pauseAccountLoading = "pending";
      })
      .addCase(pauseAccount.fulfilled, (state, action) => {
        state.pauseAccountUpdateStatus = action.payload as LockedSuccessStatus;
        state.pauseAccountLoading = "succeeded";
      })
      .addCase(pauseAccount.rejected, (state, action) => {
        state.pauseAccountUpdateStatus = action.payload as LockedSuccessStatus;
        state.pauseAccountLoading = "failed";
      })
      // POST: Free account
      .addCase(setFreeAccount.pending, (state) => {
        state.freeAccountLoading = "pending";
      })
      .addCase(setFreeAccount.fulfilled, (state, action) => {
        state.freeAccountLoading = "succeeded";
        state.freeAccountStatus = action.payload as FreeAccountSuccessStatus;
      })
      .addCase(setFreeAccount.rejected, (state, action) => {
        state.freeAccountLoading = "failed";
        state.freeAccountStatus = action.payload as FreeAccountSuccessStatus;
      })
      // POST: Mark for deletion
      .addCase(setMarkForDeletion.pending, (state) => {
        state.markForDeletionLoading = "pending";
      })
      .addCase(setMarkForDeletion.fulfilled, (state, action) => {
        state.markForDeletionLoading = "succeeded";
      })
      .addCase(setMarkForDeletion.rejected, (state) => {
        state.markForDeletionLoading = "failed";
      })
      // POST: Trial accept or deny
      .addCase(trialAcceptOrDeny.pending, (state) => {
        state.trialAcceptOrDenyLoading = "pending";
      })
      .addCase(trialAcceptOrDeny.fulfilled, (state) => {
        state.trialAcceptOrDenyLoading = "succeeded";
      })
      .addCase(trialAcceptOrDeny.rejected, (state) => {
        state.trialAcceptOrDenyLoading = "failed";
      })
      // PUT: Nesting seconds
      .addCase(setNestingSeconds.pending, (state) => {
        state.nestingSecondsLoading = "pending";
      })
      .addCase(setNestingSeconds.fulfilled, (state) => {
        state.nestingSecondsLoading = "succeeded";
      })
      .addCase(setNestingSeconds.rejected, (state) => {
        state.nestingSecondsLoading = "failed";
      })
      // PUT: Export limit
      .addCase(setExportLimit.pending, (state) => {
        state.exportLimitLoading = "pending";
      })
      .addCase(setExportLimit.fulfilled, (state) => {
        state.exportLimitLoading = "succeeded";
      })
      .addCase(setExportLimit.rejected, (state) => {
        state.exportLimitLoading = "failed";
      })
      // PUT: Max Unique Nests For Reports
      .addCase(setMaxUniqueNestsForReports.pending, (state) => {
        state.reportMaxNestsLoading = "pending";
      })
      .addCase(setMaxUniqueNestsForReports.fulfilled, (state) => {
        state.reportMaxNestsLoading = "succeeded";
      })
      .addCase(setMaxUniqueNestsForReports.rejected, (state) => {
        state.reportMaxNestsLoading = "failed";
      });
  },
});

export const { disposeUserState } = searchUserSlice.actions;

export default searchUserSlice.reducer;
