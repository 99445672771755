import { ErrorResponse, JsonServiceClient } from "@servicestack/client";
import {
  GetImportSessionsForDate,
  HistoricImportSession,
} from "../../service/api.dtos";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Loading } from "../../store/commonTypes";

type ImportReportsState = {
  importSessions: HistoricImportSession[];
  importSessionsLoading: Loading;
};

const initialState: ImportReportsState = {
  importSessions: [],
  importSessionsLoading: "idle",
};
const client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);

export const fetchImportSessionsForDateRange = createAsyncThunk(
  "importReports/fetchImportSessionsForDateRange",
  async (
    { startDate, endDate, userId, teamId }: GetImportSessionsForDate,
    thunkAPI
  ) => {
    return await client
      .get(new GetImportSessionsForDate({ startDate, endDate, userId, teamId }))
      .then((data) => {
        return data;
      })
      .catch((error: ErrorResponse) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const importReportsSlice = createSlice({
  initialState,
  name: "importReports",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImportSessionsForDateRange.pending, (state, action) => {
        state.importSessionsLoading = "pending";
      })
      .addCase(fetchImportSessionsForDateRange.fulfilled, (state, action) => {
        state.importSessionsLoading = "succeeded";
        state.importSessions = action.payload;
      })
      .addCase(fetchImportSessionsForDateRange.rejected, (state) => {
        state.importSessionsLoading = "failed";
        state.importSessions = [];
      });
  },
});

export default importReportsSlice.reducer;
