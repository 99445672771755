import { TableHeader } from "../../Sort";

export function useImportReportHeaders() {
  const initialImportReportSortHeaders = [
    {
      property: "firstName",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "First Name",

      cssClass: "text-nowrap",
    },
    {
      property: "surname",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Surname",

      cssClass: "text-nowrap",
    },
    {
      property: "createdDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Created",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "completeDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Complete",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "cancelledDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Cancelled",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "finalPartCount",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Part Count",
      tableCellWidth: "1%",
      cssClass: "text-center text-nowrap",
    },
    {
      property: "finalFileCount",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "File Count",
      tableCellWidth: "1%",
      cssClass: "text-center text-nowrap",
    },
  ] as TableHeader[];

  return initialImportReportSortHeaders;
}
