import {
  ExtentUsersPlan,
  FreeAccountById,
  LockUserById,
  MarkUserForDeletion,
  MaxFileSizeById,
  SetMaxImportLineCountPerPart,
} from "../../service/api.dtos";
import {
  addMinutes,
  maxFileUploadSize,
  pauseAccount,
  setExportLimit,
  setFreeAccount,
  setMarkForDeletion,
  setMaxImportLineCountPerPart,
  setMaxUniqueNestsForReports,
  setNestingSeconds,
} from "../../store/searchUserSlice";
import { faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import {
  getAddMinutesLoading,
  getExportLimitLoading,
  getFreeAccountLoading,
  getMarkForDeletionLoading,
  getMaxEntitiesCountLoading,
  getMaxFileUploadSizeLoading,
  getNestingSecondsLoading,
  getPauseAccountLoading,
  getReportMaxNestsLoading,
  getUserProfileData,
  getUserProfileDataLoading,
} from "../../store/searchUser";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import useConfirmIntercept from "../../hooks/useConfirmIntercept";

export default function UserInfo() {
  const dispatch = useAppDispatch();
  const addMinutesLoading = useAppSelector(getAddMinutesLoading);
  const freeAccountLoading = useAppSelector(getFreeAccountLoading);
  const loading = useAppSelector(getUserProfileDataLoading);
  const maxEntitiesLoading = useAppSelector(getMaxEntitiesCountLoading);
  const maxFileUploadSizeLoading = useAppSelector(getMaxFileUploadSizeLoading);
  const pauseAccountLoading = useAppSelector(getPauseAccountLoading);
  const userProfileData = useAppSelector(getUserProfileData);
  const markForDeletionLoading = useAppSelector(getMarkForDeletionLoading);
  const nestingSecondsLoading = useAppSelector(getNestingSecondsLoading);
  const exportLimitLoading = useAppSelector(getExportLimitLoading);
  const reportMaxNestsLoading = useAppSelector(getReportMaxNestsLoading);

  const click = useConfirmIntercept();

  //State
  const [addMinutesState, setAddMinutesState] = useState<number | null>();
  const [maxFileUploadSizeState, setMaxFileUploadSizeState] =
    useState<number>();
  const [maxEntitiesState, setMaxEntitiesState] = useState<number>();
  const [freeAccountDateState, setFreeAccountDateState] = useState<string>();
  const [markForDeletionState, setMarkForDeletionState] = useState<string>();
  const [nestingSecondsState, setNestingSecondsState] = useState<number>();
  const [exportLimitState, setExportLimitState] = useState<string>();

  const [maxUniqueNestsForReportsState, setMaxUniqueNestsForReportsState] =
    useState<string>("");

  useEffect(() => {
    setAddMinutesState(null);
    setMaxFileUploadSizeState(userProfileData.maxFileUploadMb);
    setMaxEntitiesState(userProfileData.maxImportLineCountPerPart);

    setFreeAccountDateState(userProfileData.freeUntil?.slice(0, 16) ?? "");

    //String builder for input type datetime-local
    const deleteAfter = userProfileData.deleteAfter
      ? new Date(userProfileData.deleteAfter).toLocaleDateString("fr-CA") +
        "T" +
        new Date(userProfileData.deleteAfter).toLocaleTimeString()
      : "";

    setMarkForDeletionState(deleteAfter);
    setNestingSecondsState(userProfileData.nestingSeconds);
    setExportLimitState(userProfileData.exportLimit?.toString() ?? "");
    setMaxUniqueNestsForReportsState(
      userProfileData.maxUniqueNests?.toString() ?? ""
    );
  }, [userProfileData]);

  function addMinutesClick() {
    dispatch(
      addMinutes({
        userId: userProfileData.user.id,
        addMinutes: addMinutesState,
      } as ExtentUsersPlan)
    );
  }

  function maxFileUploadSizeClick() {
    dispatch(
      maxFileUploadSize({
        userId: userProfileData.user.id,
        setValueTo: maxFileUploadSizeState,
      } as MaxFileSizeById)
    );
  }

  function setMaxEntitiesHandler() {
    dispatch(
      setMaxImportLineCountPerPart({
        userId: userProfileData.user.id,
        maxImportLineCount: maxEntitiesState,
      } as SetMaxImportLineCountPerPart)
    );
  }

  function pauseAccountChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch(
      pauseAccount({
        setLockValueTo: e.currentTarget.checked,
      } as LockUserById)
    );
  }

  function freeAccountChangeToggle(e: React.ChangeEvent<HTMLInputElement>) {
    const current = userProfileData.freeUntil
      ? new Date(userProfileData.freeUntil)
      : new Date();

    current.setDate(current.getDate() + 7);

    dispatch(
      setFreeAccount({
        setValueTo: e.currentTarget.checked,
        freeUntil: current.toISOString(),
      } as FreeAccountById)
    )
      .unwrap()
      .then((result) => {
        if (result.failureReason) {
          dispatch(
            addToastMessage({
              id: new Date().getTime().toString(),
              severity: "danger",
              text: result.failureReason,
            })
          );
        }
      });
  }

  function markForDeletionChangeToggle(check: boolean) {
    const current = userProfileData.deleteAfter
      ? new Date(userProfileData.deleteAfter)
      : new Date();

    current.setDate(current.getDate() + 7);

    dispatch(
      setMarkForDeletion({
        deleteAfter: check ? current.toISOString() : null,
      } as MarkUserForDeletion)
    )
      .unwrap()
      .then((result) => {})
      .catch((responseStatus) => {
        setMarkForDeletionState(
          userProfileData.deleteAfter?.slice(0, 16) ?? ""
        );
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: responseStatus?.message ?? "Error",
          })
        );
      });
  }

  function handleFreeAccountDateSubmit(): void {
    dispatch(
      setFreeAccount({
        setValueTo: true,
        freeUntil: freeAccountDateState,
      } as FreeAccountById)
    );
  }

  function handleMarkForDeletionSubmit(): void {
    dispatch(
      setMarkForDeletion({
        deleteAfter: markForDeletionState,
      } as MarkUserForDeletion)
    )
      .unwrap()
      .then((result) => {})
      .catch((error) => {
        setMarkForDeletionState(
          userProfileData.deleteAfter?.slice(0, 16) ?? ""
        );

        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: error?.responseStatus?.message ?? "Error",
          })
        );
      });
  }

  return (
    <>
      <dl className="row justify-content-between">
        {/* User */}

        <dt className="col-sm-5">Name</dt>
        <dd className="col-sm-7">{userProfileData.user.displayName}</dd>

        {/* Email */}
        <dt className="col-sm-5">Email</dt>
        <dd className="col-sm-7">{userProfileData.user.email}</dd>

        {/* id */}
        <dt className="col-sm-5">User ID</dt>
        <dd className="col-sm-7 font-monospace">{userProfileData.user.id}</dd>

        {/* Created Date */}
        <dt className="col-sm-5">Created Date</dt>
        <dd className="col-sm-7">
          {userProfileData.user.createdDate
            ? new Date(userProfileData.user.createdDate)?.toLocaleDateString(
                "en-GB",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }
              )
            : "--"}
        </dd>
      </dl>
      {/* -------------------- bottom left -------------------- */}
      <dl className="row ">
        {/* Logged In */}
        <dt className="col-sm-5">Logged In</dt>
        <dd className="col-sm-7">
          {userProfileData.isLoggedIn ? (
            <FontAwesomeIcon icon={faCheck} className="me-2" fixedWidth />
          ) : (
            "--"
          )}
        </dd>

        {/* Pause Account */}
        <dt className="col-sm-5">
          <label className="form-check-label" htmlFor="pauseAccount">
            Pause Account
          </label>
        </dt>
        <dd className="col-sm-7">
          <div className="form-check form-switch mt-auto">
            {pauseAccountLoading === "pending" || loading === "pending" ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                fixedWidth
                spin
                className="ms-n4"
              />
            ) : (
              <input
                className="form-check-input"
                type="checkbox"
                id="pauseAccount"
                checked={userProfileData.isLocked}
                onChange={pauseAccountChange}
              />
            )}
          </div>
        </dd>

        {/* Free Account */}
        <dt className="col-sm-5 col-form-label col-form-label-sm">
          <label className="form-check-label" htmlFor="freeAccount">
            Free Account
          </label>
        </dt>

        <dd className="col-sm-7">
          <div className="d-flex justify-content-between align-items-center">
            <div className="form-check form-switch mb-0 ">
              {freeAccountLoading === "pending" || loading === "pending" ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  fixedWidth
                  spin
                  className="ms-n4"
                />
              ) : (
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="freeAccount"
                  checked={userProfileData.isFreeAccount}
                  onChange={freeAccountChangeToggle}
                />
              )}
            </div>
            {/* Free account date */}
            <div className="ms-2 w-100">
              <div className="input-group input-group-sm">
                <input
                  type="datetime-local"
                  className="form-control "
                  id="freeAccountDate"
                  onChange={(e) =>
                    setFreeAccountDateState(e.currentTarget.value)
                  }
                  value={freeAccountDateState || ""}
                />
                <button
                  className="btn btn-sm btn-secondary border bg-dark"
                  onClick={handleFreeAccountDateSubmit}
                >
                  Update
                </button>
              </div>
            </div>
            {/* Free Account plus 7 days */}
            <div className="ms-2">
              <button
                title="Plus 7 days"
                className="btn btn-sm btn-secondary border bg-dark"
                onClick={(event) =>
                  freeAccountChangeToggle({
                    currentTarget: { checked: true },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              >
                +7
              </button>
            </div>
          </div>
        </dd>

        {/* Mark For Deletion */}
        <dt className="col-sm-5 col-form-label col-form-label-sm">
          <label className="form-check-label" htmlFor="markForDeletion">
            Mark For Deletion
          </label>
        </dt>

        <dd className="col-sm-7">
          <div className="d-flex justify-content-between align-items-center">
            <div className="form-check form-switch mb-0">
              {markForDeletionLoading === "pending" || loading === "pending" ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  fixedWidth
                  spin
                  className="ms-n4"
                />
              ) : (
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="markForDeletion"
                  defaultChecked={userProfileData.isMarkedForDeletion}
                  onClick={(event) =>
                    click({
                      event: event,
                      message: !userProfileData.isMarkedForDeletion
                        ? "Are you sure you want to mark this account for deletion?"
                        : "Are you sure you want to remove deletion request?",

                      callback: () =>
                        markForDeletionChangeToggle(
                          !userProfileData.isMarkedForDeletion
                        ),
                    })
                  }
                />
              )}
            </div>
            {/* Marked For Deletion Date */}
            <div className="ms-2 w-100">
              <div className="input-group input-group-sm  ">
                <input
                  type="datetime-local"
                  className={`form-control ${
                    userProfileData.isMarkedForDeletion && "bg-danger"
                  }`}
                  id="markForDeletionDate"
                  onChange={(event) =>
                    setMarkForDeletionState(event.currentTarget.value)
                  }
                  value={markForDeletionState || ""}
                />
                <button
                  className="btn btn-sm btn-secondary border bg-dark"
                  onClick={() =>
                    click({
                      message:
                        "Are you sure you want to mark this account for deletion?",
                      callback: handleMarkForDeletionSubmit,
                    })
                  }
                >
                  Update
                </button>
              </div>
            </div>
            {/* Mark For Deletion plus 7 days */}
            <div className="ms-2">
              <button
                title="Plus 7 days"
                className="btn btn-sm btn-secondary border bg-dark"
                onClick={(event) =>
                  click({
                    event: event,
                    message:
                      "Are you sure you want to mark this account for deletion?",
                    callback: () =>
                      markForDeletionChangeToggle(
                        !userProfileData.isMarkedForDeletion
                      ),
                  })
                }
              >
                +7
              </button>
            </div>
          </div>
        </dd>
      </dl>

      {/* Extend Time */}
      <div className="row mt-auto">
        <label
          htmlFor="updateMinutes"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Extend Time
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark">
              Minutes
            </span>
            <input
              disabled={
                userProfileData.isLocked || addMinutesLoading === "pending"
              }
              type="number"
              className="form-control"
              id="updateMinutes"
              value={addMinutesState || ""}
              onChange={(e) => setAddMinutesState(parseInt(e.target.value, 10))}
            />
            <button
              disabled={
                userProfileData.isLocked || addMinutesLoading === "pending"
              }
              className="btn btn-secondary border bg-dark"
              onClick={addMinutesClick}
            >
              {addMinutesLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Max Fille Upload */}
      <div className="row">
        <label
          htmlFor="maxFileUploadSize"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Max File Upload
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark text-center">
              MB
            </span>
            <input
              disabled={
                userProfileData.isLocked ||
                maxFileUploadSizeLoading === "pending"
              }
              type="number"
              className="form-control"
              id="maxFileUploadSize"
              value={maxFileUploadSizeState || ""}
              onChange={(e) =>
                setMaxFileUploadSizeState(parseInt(e.currentTarget.value, 10))
              }
            />
            <button
              disabled={
                userProfileData.isLocked ||
                maxFileUploadSizeLoading === "pending"
              }
              className="btn btn-secondary border bg-dark"
              onClick={maxFileUploadSizeClick}
            >
              {maxFileUploadSizeLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Max Entities */}
      <div className="row">
        <label
          htmlFor="maxEntities"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Max Entities Per Part
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark text-center">
              Lines
            </span>
            <input
              disabled={
                userProfileData.isLocked || maxEntitiesLoading === "pending"
              }
              type="number"
              className="form-control"
              id="maxEntities"
              value={maxEntitiesState || ""}
              onChange={(e) =>
                setMaxEntitiesState(parseInt(e.currentTarget.value, 10))
              }
            />
            <button
              disabled={
                userProfileData.isLocked || maxEntitiesLoading === "pending"
              }
              className="btn btn-secondary border bg-dark"
              onClick={setMaxEntitiesHandler}
            >
              {maxEntitiesLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Nesting Seconds */}
      <div className=" row">
        <label
          htmlFor="nestingSeconds"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Nesting Seconds
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark text-center">
              Seconds
            </span>
            <input
              disabled={userProfileData.isLocked}
              type="number"
              className="form-control"
              id="nestingSeconds"
              value={nestingSecondsState || ""}
              onChange={(e) =>
                setNestingSecondsState(parseInt(e.currentTarget.value, 10))
              }
            />
            <button
              disabled={userProfileData.isLocked}
              className="btn btn-secondary border bg-dark"
              onClick={() =>
                dispatch(
                  setNestingSeconds({
                    seconds: nestingSecondsState,
                  })
                )
              }
            >
              {nestingSecondsLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Export Limit */}
      <div className="row">
        <label
          htmlFor="exportLimit"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Nests Exported Per Month
        </label>
        <div className="col-sm-7">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text w-25 text-muted bg-dark text-center ">
              Count
            </span>
            <input
              disabled={userProfileData.isLocked}
              min="0"
              type="number"
              className="form-control"
              id="exportLimit"
              value={exportLimitState || ""}
              onChange={(e) => setExportLimitState(e.currentTarget.value ?? "")}
            />
            <button
              disabled={userProfileData.isLocked}
              className="btn btn-secondary border bg-dark"
              onClick={() => {
                if (exportLimitState) {
                  dispatch(
                    setExportLimit({
                      limit: parseInt(exportLimitState, 10),
                    })
                  );
                }
              }}
            >
              {exportLimitLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Report Max Nests */}
      <div className="row mb-3">
        <label
          htmlFor="maxUniqueNestsForReports"
          className={`col-sm-5 col-form-label col-form-label-sm fw-bold ${
            userProfileData.isLocked ? "text-muted theme-disabled" : ""
          } `}
        >
          Max Unique Nests For Reports
        </label>

        <div className="col-sm-7">
          <div className="input-group input-group-sm ">
            <span className="input-group-text w-25 text-muted bg-dark text-center">
              Count
            </span>
            <input
              disabled={userProfileData.isLocked}
              min="0"
              type="number"
              className="form-control"
              id="maxUniqueNestsForReports"
              value={maxUniqueNestsForReportsState}
              onChange={(e) =>
                setMaxUniqueNestsForReportsState(e.currentTarget.value ?? "")
              }
            />
            <button
              disabled={userProfileData.isLocked}
              className="btn btn-secondary border bg-dark"
              onClick={() => {
                if (maxUniqueNestsForReportsState) {
                  dispatch(
                    setMaxUniqueNestsForReports({
                      maxUniqueNests: parseInt(
                        maxUniqueNestsForReportsState,
                        10
                      ),
                    })
                  );
                }
              }}
            >
              {reportMaxNestsLoading === "pending" ? (
                <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
              ) : (
                <span>Update</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/*  */}
    </>
  );
}
