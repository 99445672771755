import { TableHeader } from "../../Sort";

export function useNestingReportsHeaders() {
  const initialNestingReportsSortHeaders = [
    {
      property: "userFirstName",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "First Name",
      cssClass: "text-nowrap",
    },
    {
      property: "userSurname",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Surname",
    },
    {
      property: "createdDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Created",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "workerReceivedDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Worker Received",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "startedDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Started",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },

    {
      property: "userInformedDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "User Informed",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "failedDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Failed",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "finishedDate",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Finished",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "efficiency",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Efficiency",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "",
      sort: {
        active: false,
        order: "desc",
        sortable: false,
        sortedIndexes: [],
      },
      title: "Parts",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "",
      sort: {
        active: false,
        order: "desc",
        sortable: false,
        sortedIndexes: [],
      },
      title: "Sheets",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "uniqueNests",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Unique Nests",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "allocatedSeconds",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Allocated",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "cancelled",
      sort: {
        active: false,
        order: "desc",
        sortable: true,
        sortedIndexes: [],
      },
      title: "Cancelled",
      tableCellWidth: "1%",
      cssClass: "text-center",
    },
    {
      property: "",
      sort: {
        active: false,
        order: "desc",
        sortable: false,
        sortedIndexes: [],
      },
      title: "",
      width: 4,
      cssClass: "text-center",
    },
  ] as TableHeader[];

  return initialNestingReportsSortHeaders;
}
