import {
  GetImportSessionsForDate,
  HistoricImportSession,
} from "../../service/api.dtos";
import { TableHeader, toggleHeaderSort } from "../../Sort";
import {
  faCircleNotch,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  getImportSessions,
  getImportSessionsForDateRangeLoading,
} from "./importReports";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxTypedHooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImportReportsBody from "./ImportReportsBody";
import ListHeader from "../../components/ListHeaders";
import LoadingOverlay from "../../components/LoadingOverlay";
import React from "react";
import { addToastMessage } from "../toastMessages/toastMessagesSlice";
import { fetchImportSessionsForDateRange } from "./importReportsSlice";
import { useHistory } from "react-router";
import { useImportReportHeaders } from "./useImportReportHeaders";
import { useQuery } from "../../utils";

const ImportReportScreen = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();
  const importSessions = useAppSelector(getImportSessions);
  const initialImportReportSortHeaders = useImportReportHeaders();
  const [sortedImportSessions, setSortedImportSessions] = useState<
    HistoricImportSession[]
  >([]);

  const [tableHeaders, setTableHeaders] = useState<TableHeader[]>([
    ...initialImportReportSortHeaders,
  ]);

  const sortingColumn = useMemo(() => {
    const active = tableHeaders.find((header) => header.sort.active);

    return active;
  }, [tableHeaders]);

  const setHeaderSort = useCallback(
    (header: TableHeader) => {
      if (header.property.length > 0) {
        setTableHeaders(
          toggleHeaderSort(header, tableHeaders, importSessions, "sessionId")
        );
      }
    },
    [importSessions, tableHeaders]
  );

  useEffect(() => {
    typeof sortingColumn !== "undefined"
      ? setSortedImportSessions(
          sortingColumn.sort.sortedIndexes.map((index) => {
            return importSessions[index];
          })
        )
      : setSortedImportSessions(importSessions);
  }, [importSessions, sortingColumn]);

  const importReportsLoading = useAppSelector(
    getImportSessionsForDateRangeLoading
  );

  const defaultDateTo = new Date().toLocaleDateString("fr-CA");
  const defaultDateFrom = new Date(
    new Date().setDate(new Date().getDate() - 7)
  ).toLocaleDateString("fr-CA");

  const [dateInputFrom, setDateInputFrom] = useState<string>(defaultDateFrom);
  const [dateInputTo, setDateInputTo] = useState<string>(defaultDateTo);

  const reportRef = React.useRef(true);

  const [id, setId] = useState<string>(
    query.get("userId") ?? query.get("teamId") ?? ""
  );

  type SearchType = "userId" | "teamId";

  const [searchType, setSearchType] = useState<SearchType>(
    query.get("userId") ? "userId" : "teamId"
  );
  const [cleared, setCleared] = useState(false);

  function clear() {
    setDateInputFrom(defaultDateFrom);
    setDateInputTo(defaultDateTo);
    setId("");
    setSearchType("userId");
    setCleared(true);

    history.push({
      search: "",
    });
  }

  function handleSearchType(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;
    setSearchType(value as SearchType);
    setId("");
  }

  const handleFetchImportReports = useCallback(() => {
    if (!dateInputFrom || !dateInputTo) return;

    const args = new GetImportSessionsForDate();
    args.startDate = dateInputFrom;
    args.endDate = dateInputTo;
    if (searchType === "userId") {
      args.userId = id;
      args.teamId = "";
    } else {
      args.teamId = id;
      args.userId = "";
    }

    dispatch(fetchImportSessionsForDateRange(args))
      .unwrap()
      .catch((e) => {
        dispatch(
          addToastMessage({
            id: new Date().getTime().toString(),
            severity: "danger",
            text: e?.responseStatus?.message ?? "Oops?",
          })
        );
      });
  }, [dateInputFrom, dateInputTo, dispatch, id, searchType]);

  useEffect(() => {
    if (cleared) {
      setCleared(false);
      handleFetchImportReports();
    }
  }, [cleared, handleFetchImportReports]);

  useEffect(() => {
    if (reportRef.current) handleFetchImportReports();
    reportRef.current = false;
  }, [handleFetchImportReports]);

  function handleIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    setId(value);
  }

  useEffect(() => {
    let stringBuilder = id ? `?${searchType}=${encodeURIComponent(id)} ` : "";

    return history.push({
      pathname: "/import-reports",
      search: stringBuilder,
    });
  }, [history, id, searchType]);

  function handleKeypress(e: any): void {
    if (e.key === "Enter") {
      handleFetchImportReports();
    }
  }

  return (
    <section className="container-fluid py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="px-3 pt-3 d-flex align-items-center">
          {/* Title */}
          <h4 className="text-nowrap">Import Reports</h4>
          <div className="rounded flex-fill mx-3 bg-secondary w-75 h-50 text-secondary">
            .
          </div>
          {/* Date Filter */}
          <div className="input-group input-group-sm">
            <>
              {/* choose between userId and teamId */}
              <select
                className="form-select"
                onChange={handleSearchType}
                value={searchType}
                style={{ minWidth: "100px" }}
              >
                <option value="userId">User Id</option>
                <option value="teamId">Team Id</option>
              </select>

              {/* Team or User Id Input */}
              <input
                id="input-importReportId"
                className="form-control"
                type="text"
                placeholder={searchType}
                onChange={handleIdChange}
                value={id}
                style={{ minWidth: "330px" }}
                onKeyUp={handleKeypress}
              />
              {/* Date Input From */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputFrom(e.target.value)}
                value={dateInputFrom}
                max={new Date().toISOString().substring(0, 10)}
              />

              {/* Date Input To */}
              <input
                className="form-control"
                type="date"
                onChange={(e) => setDateInputTo(e.target.value)}
                value={dateInputTo}
                max={new Date().toISOString().substring(0, 10)}
              />
              {/* Reports amount */}
              <div
                className="input-group-text bg-dark"
                style={{ minWidth: "70px" }}
              >
                {importSessions.length}
              </div>

              {/* Clear */}
              <button
                className="btn btn-secondary border bg-dark"
                type="button"
                onClick={clear}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </button>
              {/* Search Button */}
              <button
                className="btn btn-secondary border bg-dark"
                id="search"
                onClick={handleFetchImportReports}
                disabled={importReportsLoading === "pending"}
              >
                {importReportsLoading === "pending" ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth />
                ) : (
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                )}
              </button>
            </>
          </div>
        </div>

        {/* Table */}
        <div className="card-body d-flex flex-grow-1 position-relative m-3 h-100">
          <div className="border rounded theme-overflow">
            {importReportsLoading === "pending" && (
              <LoadingOverlay loading={"pending"} />
            )}
            {importReportsLoading !== "pending" &&
              (importReportsLoading === "failed" ||
                !sortedImportSessions?.length) && (
                <h4 className="text-center p-5">No results</h4>
              )}
            {importReportsLoading !== "pending" &&
              importReportsLoading !== "failed" &&
              sortedImportSessions?.length > 0 && (
                <div className="theme-overflow theme-overflow-table table-responsive  ">
                  <table
                    id="table-reports"
                    className="table table-dark table-hover mb-0"
                  >
                    <thead className="sticky-top border-bottom">
                      <tr>
                        {tableHeaders.map((header, index) => (
                          <ListHeader
                            key={index}
                            header={header}
                            toggleHeaderSort={setHeaderSort}
                            canSort={sortedImportSessions.length > 1}
                          />
                        ))}
                      </tr>
                    </thead>
                    <ImportReportsBody importSessions={sortedImportSessions} />
                  </table>
                </div>
              )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportReportScreen;
