/* Options:
Date: 2024-11-19 09:38:36
Version: 8.0
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://dev.api.mynesting.com

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/

export interface IReturn<T> {
  createResponse(): T;
}

export interface IReturnVoid {
  createResponse(): void;
}

export interface IHasSessionId {
  sessionId: string;
}

export interface IHasBearerToken {
  bearerToken: string;
}

export interface IPost {}

export interface IGet {}

export interface IDelete {}

export interface IPut {}

export class PartCompleteData {
  public id: string;
  public name: string;
  public isSelected: boolean;
  public quantity: number;
  public priority: number;

  public constructor(init?: Partial<PartCompleteData>) {
    (Object as any).assign(this, init);
  }
}

export class CompletedSessionFileOptions {
  public uniqueId: string;
  public fileId: string;
  public parts: PartCompleteData[];

  public constructor(init?: Partial<CompletedSessionFileOptions>) {
    (Object as any).assign(this, init);
  }
}

export interface ITraceableRequest {
  uniqueRequestId: string;
}

export class Ratio {
  public from: number;
  public to: number;

  public constructor(init?: Partial<Ratio>) {
    (Object as any).assign(this, init);
  }
}

export enum MeasurementUnits {
  Millimetres = 1,
  Inches = 2,
}

export class FileChangeableSettings {
  public drawingScale: Ratio;
  public measurementUnit: MeasurementUnits;

  public constructor(init?: Partial<FileChangeableSettings>) {
    (Object as any).assign(this, init);
  }
}

export enum AssignmentType {
  NotAssigned = "NotAssigned",
  Cut = "Cut",
  Attach = "Attach",
  Ignore = "Ignore",
}

export class Assignment {
  public name: string;
  public assignmentType: AssignmentType;

  public constructor(init?: Partial<Assignment>) {
    (Object as any).assign(this, init);
  }
}

export class Layer {
  public visible: boolean;
  public colourHex: string;
  public layerName: string;
  public lineType: string;
  public instruction: Assignment;

  public constructor(init?: Partial<Layer>) {
    (Object as any).assign(this, init);
  }
}

export class IdentifiableLayer extends Layer {
  public uiIdentifier: string;

  public constructor(init?: Partial<IdentifiableLayer>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export enum ParametricPartType {
  CircleRadius = "CircleRadius",
  RectangleXY = "RectangleXY",
  RingOuterRadiusInnerRadius = "RingOuterRadiusInnerRadius",
}

export class ParametricPartDefinition {
  public name: string;
  public orderedDimensions: number[];
  public quantity: number;
  public type: ParametricPartType;

  public constructor(init?: Partial<ParametricPartDefinition>) {
    (Object as any).assign(this, init);
  }
}

export enum FolderSearchType {
  Global = "Global",
  Subfolders = "Subfolders",
}

export class PartSearchParameter {
  public field: string;
  public valueOne: string;
  public valueTwo: string;
  public operation: string;

  public constructor(init?: Partial<PartSearchParameter>) {
    (Object as any).assign(this, init);
  }
}

export enum MirrorDirection {
  None = "None",
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

export enum LibraryType {
  Part = 1,
  Sheet = 2,
}

export class CreateCircle {
  public radius: number;

  public constructor(init?: Partial<CreateCircle>) {
    (Object as any).assign(this, init);
  }
}

export class CreateRectangle {
  public x: number;
  public y: number;

  public constructor(init?: Partial<CreateRectangle>) {
    (Object as any).assign(this, init);
  }
}

export class CreateRing {
  public innerRadius: number;
  public outerRadius: number;

  public constructor(init?: Partial<CreateRing>) {
    (Object as any).assign(this, init);
  }
}

export class UsageOption {
  public id: number;
  public name: string;
  public otherText: string;

  public constructor(init?: Partial<UsageOption>) {
    (Object as any).assign(this, init);
  }
}

export enum ReportSizes {
  Letter = "Letter",
  Legal = "Legal",
  Tabloid = "Tabloid",
  Ledger = "Ledger",
  A0 = "A0",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
}

export enum AutoCadFileType {
  Dxf = "Dxf",
  Dwg = "Dwg",
}

export enum AutoCadVersion {
  Dxf10 = 6,
  Dxf12 = 9,
  Dxf13 = 12,
  Dxf14 = 14,
  Dxf15 = 15,
  Dxf18 = 18,
  Dxf21 = 21,
  Dxf24 = 24,
  Dxf27 = 27,
  Dxf32 = 32,
}

export enum ExportedGeometryStructure {
  LinesAndArcs = "LinesAndArcs",
  Polylines = "Polylines",
}

export class LayerRule {
  public constructor(init?: Partial<LayerRule>) {
    (Object as any).assign(this, init);
  }
}

export class AutoCadExportSettings {
  public fileType: AutoCadFileType;
  public autoCadVersion: AutoCadVersion;
  public structure: ExportedGeometryStructure;
  public partsAsBlocks: boolean;
  public nestsAsBlocks: boolean;
  public allPartsOnSingleLayer: boolean;
  public allPartsLayerName: string;
  public sheetsLayerName: string;
  public labelsLayerName: string;
  public attachedLayerRule: LayerRule;
  public repeatFirstVertexOnClosedPolylines: boolean;
  public splitCirclesIntoArcPairs: boolean;
  public useLWPolyLine: boolean;

  public constructor(init?: Partial<AutoCadExportSettings>) {
    (Object as any).assign(this, init);
  }
}

export enum SheetExportSettings {
  SingleFile = "SingleFile",
  MultipleFile = "MultipleFile",
}

export enum LoopDirection {
  Clockwise = "Clockwise",
  CounterClockwise = "CounterClockwise",
}

export class NestExportSettings {
  public outputNestOrientationAngleCCW: number;
  public addNestStatistics: boolean;
  public outputSheetBoundaries: boolean;
  public sheetExportSettings: SheetExportSettings;
  public removeCommonEdges: boolean;
  public outerLoopDirection: LoopDirection;
  public innerLoopDirection: LoopDirection;
  public exportUnits: MeasurementUnits;
  public addPartLabels: boolean;
  public appendDimensionsToFileName: boolean;
  public appendQuantityToFileName: boolean;
  public appendSheetIdentifierToFileName: boolean;
  public commonEdgeRemovalTolerance: number;

  public constructor(init?: Partial<NestExportSettings>) {
    (Object as any).assign(this, init);
  }
}

export class PartExportSettings {
  public addPartName: boolean;
  public outerLoopDirection: LoopDirection;
  public innerLoopDirection: LoopDirection;
  public exportUnits: MeasurementUnits;

  public constructor(init?: Partial<PartExportSettings>) {
    (Object as any).assign(this, init);
  }
}

export enum NestingDirectionSetting {
  East = 0,
  North = 90,
  West = 180,
  South = 270,
}

export enum RepeatOption {
  BestEfficiency = 1,
  PreferRepeats = 2,
  BalancedRepeats = 3,
}

export enum AreaMeasurementUnits {
  Unknown = 0,
  MillimeterSquared = 1,
  MeterSquared = 2,
  SquareInches = 11,
  SquareFoot = 12,
}

export class TeamSheets {
  public id: string;
  public name: string;
  public quantity: number;
  public teamId: string;
  public sheetDimensionXDisplay: number;
  public sheetDimensionYDisplay: number;
  public priority: number;

  public constructor(init?: Partial<TeamSheets>) {
    (Object as any).assign(this, init);
  }
}

export enum NamingScheme {
  Filename = 1,
  TextLabel = 2,
  Block = 3,
}

export enum CameraBehaviour {
  MoveWork = 1,
  MoveCamera = 2,
}

export enum Rotations {
  Any = 0,
  Ninety = 90,
  OneHundredAndEighty = 180,
  Zero = -1,
}

export class UserSettings implements IUserSettings {
  public defaultNamingScheme: NamingScheme;
  public measurementUnits: MeasurementUnits;
  public importBlocksIndependently: boolean;
  public panZoomMode: CameraBehaviour;
  public defaultAllowedRotations: Rotations;
  public defaultPartSpacingDisplay: number;
  public defaultSheetSpacingDisplay: number;
  public defaultSheetQuantity: number;
  public mirrorDefault: boolean;

  public constructor(init?: Partial<UserSettings>) {
    (Object as any).assign(this, init);
  }
}

export interface IUserSettings {
  defaultNamingScheme: NamingScheme;
  measurementUnits: MeasurementUnits;
  importBlocksIndependently: boolean;
  panZoomMode: CameraBehaviour;
}

export enum ExampleProjectTypes {
  Default = "Default",
}

export class SheetGeometrySettings {
  public id: string;
  public xDimensionDisplay: number;
  public yDimensionDisplay: number;
  public name: string;

  public constructor(init?: Partial<SheetGeometrySettings>) {
    (Object as any).assign(this, init);
  }
}

export class LayerMetaData {
  public layerName: string;
  public distance: number;

  public constructor(init?: Partial<LayerMetaData>) {
    (Object as any).assign(this, init);
  }
}

export class MNOPart {
  public name: string;
  public base64Svg: string;
  public xDimensionDisplay: number;
  public yDimensionDisplay: number;
  public xDimensionMM: number;
  public yDimensionMM: number;
  public areaDisplay: number;
  public areaMM: number;
  public cutDistanceMM: number;
  public cutDistanceDisplay: number;
  public entryPoints: number;
  public layerMetaDataDisplay: LayerMetaData[];
  public layerMetaDataMM: LayerMetaData[];
  public libraryId?: string;
  public nonLibraryPartId?: string;
  public isLibraryPart: boolean;
  public version: number;

  public constructor(init?: Partial<MNOPart>) {
    (Object as any).assign(this, init);
  }
}

export class NestingPart extends MNOPart {
  public base64PartDefinition: string;
  public tilt: number;
  public allowMirror: boolean;
  public quantity: number;
  public priority: number;
  public allowedRotation: Rotations;

  public constructor(init?: Partial<NestingPart>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class NestingSheet extends NestingPart {
  public sheetDimensionXMM?: number;
  public sheetDimensionYMM?: number;
  public sheetDimensionXDisplay?: number;
  public sheetDimensionYDisplay?: number;
  public leftSpacingDisplay: number;
  public rightSpacingDisplay: number;
  public topSpacingDisplay: number;
  public bottomSpacingDisplay: number;
  public leftSpacingMM: number;
  public rightSpacingMM: number;
  public topSpacingMM: number;
  public bottomSpacingMM: number;
  public importedSheet: boolean;

  public constructor(init?: Partial<NestingSheet>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export enum Multiplicity {
  BestEfficiency = "BestEfficiency",
  Normal = "Normal",
  Aggressive = "Aggressive",
}

export class NestSettings {
  public showOnlyUniqueNests: boolean;
  public nestingDirection: number;
  public nestingRepeatType: Multiplicity;
  public partSpacingMM?: number;
  public partSpacingDisplay?: number;
  public sheetSpacingMM?: number;
  public sheetSpacingDisplay?: number;
  public leftSheetSpacingMM?: number;
  public topSheetSpacingMM?: number;
  public rightSheetSpacingMM?: number;
  public bottomSheetSpacingMM?: number;
  public leftSheetSpacingDisplay?: number;
  public topSheetSpacingDisplay?: number;
  public rightSheetSpacingDisplay?: number;
  public bottomSheetSpacingDisplay?: number;

  public constructor(init?: Partial<NestSettings>) {
    (Object as any).assign(this, init);
  }
}

export class Colour {
  public a: number;
  public r: number;
  public g: number;
  public b: number;
  public opacityString: string;

  public constructor(init?: Partial<Colour>) {
    (Object as any).assign(this, init);
  }
}

export class Geometric {
  public layerName: string;
  public color: Colour;
  public lineType: string;
  public assignment: Assignment;

  public constructor(init?: Partial<Geometric>) {
    (Object as any).assign(this, init);
  }
}

export class CommonCut {
  public part1Identity: string;
  public part2Identity: string;
  public commonEdgeStartX: number;
  public commonEdgeStartY: number;
  public commonEdgeEndX: number;
  public commonEdgeEndY: number;
  public part1SourceEdgeStartX: number;
  public part1SourceEdgeStartY: number;
  public part1SourceEdgeEndX: number;
  public part1SourceEdgeEndY: number;
  public part2SourceEdgeStartX: number;
  public part2SourceEdgeStartY: number;
  public part2SourceEdgeEndX: number;
  public part2SourceEdgeEndY: number;

  public constructor(init?: Partial<CommonCut>) {
    (Object as any).assign(this, init);
  }
}

export class NestedSheet2D {
  public xMax: number;
  public yMax: number;
  public sheetIdentity: string;
  public partPlacements: { [index: string]: Placement2D[] };
  public multiplicity: number;
  public sheetUtilisation: number;
  public additionalDrawables: Geometric[];
  public nestingEfficiency: number;
  public legacyZeroZeroBasedSheetPlacements: boolean;
  public commonCuts: CommonCut[];

  public constructor(init?: Partial<NestedSheet2D>) {
    (Object as any).assign(this, init);
  }
}

export class NestResult2D {
  public definitive: boolean;
  public averageSheetUtilisation: number;
  public nestedSheets: NestedSheet2D[];
  public nestedPartCount: number;
  public unplacedParts: { [index: string]: number };
  public nestXLengthInMM: number;
  public nestingEfficiency: number;
  public created: string;

  public constructor(init?: Partial<NestResult2D>) {
    (Object as any).assign(this, init);
  }
}

export class NestingResult {
  public id: string;
  public nestResult: NestResult2D;

  public constructor(init?: Partial<NestingResult>) {
    (Object as any).assign(this, init);
  }
}

export enum JobStatus {
  AgentAssigned = 1,
  NestingStarted = 2,
  ResultGenerated = 3,
  NestComplete = 4,
  Error = 99,
}

export enum SaveOrSaveAs {
  Save = 1,
  SaveAs = 2,
}

export class NestSearchParams {
  public nameLike: string;
  public fromDate?: string;
  public toDate?: string;
  public skip: number;
  public take: number;
  public orderBy: string;
  public orderByDesc: string;

  public constructor(init?: Partial<NestSearchParams>) {
    (Object as any).assign(this, init);
  }
}

export enum GoodOrBadFeedback {
  Bad = "Bad",
  Neutral = "Neutral",
  Good = "Good",
}

export enum TimeRange {
  Hour = 1,
  Six = 6,
  Day = 24,
}

export class ChartRangeData {
  public minutesPeriod: number;
  public fromDate: string;
  public toDate: string;

  public constructor(init?: Partial<ChartRangeData>) {
    (Object as any).assign(this, init);
  }
}

export enum TrialAction {
  Accept = "Accept",
  Reject = "Reject",
}

export class BaseRenderSizing {
  public height?: number;
  public width?: number;

  public constructor(init?: Partial<BaseRenderSizing>) {
    (Object as any).assign(this, init);
  }
}

export class BasePartRenderer extends BaseRenderSizing {
  public partId: string;
  public projectId: string;

  public constructor(init?: Partial<BasePartRenderer>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export interface INestingRenderRequest {}

export interface IPartRenderRequest {
  partId: string;
}

export class BaseSheetRenderer extends BaseRenderSizing {
  public sheetId: string;
  public projectId: string;

  public constructor(init?: Partial<BaseSheetRenderer>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class BaseNestRenderer extends BaseRenderSizing {
  public resultId: string;
  public projectId: string;

  public constructor(init?: Partial<BaseNestRenderer>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class CSVHeaderAssignments {
  public quantity: string;
  public name: string;
  public dimX: string;
  public dimY: string;
  public delimiter: string;

  public constructor(init?: Partial<CSVHeaderAssignments>) {
    (Object as any).assign(this, init);
  }
}

export enum ExportType {
  Dxf = 1,
  Report = 2,
}

export interface IHasLinkedFileDownload {}

export class PartData {
  public x: number;
  public y: number;
  public labels: string[];
  public isSelected: boolean;
  public quantity: number;
  public name: string;
  public base64EncodedPng: string;
  public partId: string;
  public areaMeasurementUnits: AreaMeasurementUnits;
  public area: number;
  public priority: number;

  public constructor(init?: Partial<PartData>) {
    (Object as any).assign(this, init);
  }
}

export enum FileStatus {
  ErrorNoPartsFound = "ErrorNoPartsFound",
  WarningNotAllGeometryUsed = "WarningNotAllGeometryUsed",
  SuccessAllGeometryUsed = "SuccessAllGeometryUsed",
}

export class IdentifiableImportResult {
  public uiIdentifier: string;
  public parts: PartData[];
  public fileId: string;
  public sessionId: string;
  public userId: string;
  public base64Svg: string;
  public status: FileStatus;

  public constructor(init?: Partial<IdentifiableImportResult>) {
    (Object as any).assign(this, init);
  }
}

export class SourceFile {
  public id: string;
  public fileName: string;
  public fileExtension: string;
  public importedDate: string;

  public constructor(init?: Partial<SourceFile>) {
    (Object as any).assign(this, init);
  }
}

export class PartGeometries {
  public id: string;
  public geometry: string;

  public constructor(init?: Partial<PartGeometries>) {
    (Object as any).assign(this, init);
  }
}

export class FolderHierarchy {
  public id: string;
  public parentId?: string;
  public name: string;
  public childFolders: FolderHierarchy[];

  public constructor(init?: Partial<FolderHierarchy>) {
    (Object as any).assign(this, init);
  }
}

export enum NestingSettingType {
  boolean = "boolean",
  decimalVal = "decimalVal",
}

export class NestingSetting {
  public name: string;
  public type: NestingSettingType;
  public value: Object;
  public defaultValue?: Object;
  public propertyName: string;

  public constructor(init?: Partial<NestingSetting>) {
    (Object as any).assign(this, init);
  }
}

export class DxfVersionValues {
  public id: number;
  public value: AutoCadVersion;
  public text: string;

  public constructor(init?: Partial<DxfVersionValues>) {
    (Object as any).assign(this, init);
  }
}

export class Profile {
  public email: string;
  public name: string;
  public team: string;
  public isFreeAccount: boolean;

  public constructor(init?: Partial<Profile>) {
    (Object as any).assign(this, init);
  }
}

export class TeamPlanDetails {
  public planName: string;
  public partCount: number;
  public teamsEnabled: boolean;
  public partsLibraryEnabled: boolean;
  public reportsEnabled: boolean;
  public includedTeamMembers: number;
  public maxPlanExports: number;
  public remainingPlanExports: number;
  public remainingPlanNestRuns: number;
  public maxPlanNestRuns: number;
  public savedNestProjects: number;
  public maxSavedNestProjects: number;
  public mbFileSize: number;

  public constructor(init?: Partial<TeamPlanDetails>) {
    (Object as any).assign(this, init);
  }
}

export class SearchedNestingProject {
  public id?: string;
  public folderId?: string;
  public name: string;
  public uniqueNests: number;
  public sheetUsedCount: number;
  public efficiency: number;
  public totalPartsToNest: number;
  public totalPartsNested: number;
  public base64PNG: string;
  public isSavedOnlySession: boolean;
  public lastUpdated?: string;
  public createdById: string;
  public createdDate: string;
  public startedDate?: string;
  public finishedDate?: string;
  public workerReceivedDate?: string;
  public userInformedDate?: string;
  public failedDate?: string;

  public constructor(init?: Partial<SearchedNestingProject>) {
    (Object as any).assign(this, init);
  }
}

export class RotatedNestingPart extends NestingPart {
  public previousLibraryPartId?: string;

  public constructor(init?: Partial<RotatedNestingPart>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class GeometryForSheet {
  public id: string;
  public base64Geometry: string;

  public constructor(init?: Partial<GeometryForSheet>) {
    (Object as any).assign(this, init);
  }
}

export enum NoteType {
  Note = 1,
  Save = 2,
  SaveAs = 3,
  Nest = 4,
  Export = 5,
  Report = 6,
  ExportAndReport = 7,
}

export class NoteDetails {
  public id: string;
  public userId: string;
  public createdDate: string;
  public name: string;
  public content: string;
  public isSystemMessage: boolean;
  public noteType: NoteType;

  public constructor(init?: Partial<NoteDetails>) {
    (Object as any).assign(this, init);
  }
}

export class SheetDetail {
  public sheetId: string;
  public multiplicity: number;
  public efficiency: number;
  public nestedPartCount: number;
  public name: string;

  public constructor(init?: Partial<SheetDetail>) {
    (Object as any).assign(this, init);
  }
}

export interface IAuthenticationUris {
  loginUri: string;
  resetPasswordUri: string;
  refreshUri: string;
}

export enum AccountStates {
  None = 0,
  Active = 1,
  PastDue = 2,
  Delinquent = 3,
  Cancelled = 4,
  Trial = 5,
  FreeAccount = 999,
  Uninitialized = -1,
}

export class PaymentInformation {
  public paymentMethod: string;
  public cardType: string;
  public lastDigits: string;
  public expiry?: string;
  public cardIsValid: boolean;
  public lastPaymentFailed: boolean;
  public lastPaymentFailedDate?: string;

  public constructor(init?: Partial<PaymentInformation>) {
    (Object as any).assign(this, init);
  }
}

export enum PaymentPlanTypes {
  Unknown = 0,
  DayPass = 1,
  Solo = 2,
  Business = 3,
  FreeAccount = 999,
  Development = -1,
}

export class PaymentReceipt {
  public orderId: string;
  public paidDate?: string;
  public gross: number;
  public currencyCode: string;
  public currencySymbol: string;
  public receiptUrl: string;
  public planName: string;
  public planDisplayName: string;
  public transactionType: string;

  public constructor(init?: Partial<PaymentReceipt>) {
    (Object as any).assign(this, init);
  }
}

export class PlanBasicData {
  public id: string;
  public name: string;
  public description: string;
  public usdPrice: number;
  public isDayPass: boolean;
  public overrideId: string;
  public billingPeriod: string;
  public trialDayCount?: number;
  public hasPopularFlag: boolean;
  public order: number;
  public upgradableFromPlanIds: string[];
  public planType: PaymentPlanTypes;

  public constructor(init?: Partial<PlanBasicData>) {
    (Object as any).assign(this, init);
  }
}

export class HistoricImportSession {
  public sessionId: string;
  public userId: string;
  public surname: string;
  public firstName: string;
  public createdDate: string;
  public cancelledDate?: string;
  public completeDate?: string;
  public finalPartCount?: number;
  public finalFileCount?: number;

  public constructor(init?: Partial<HistoricImportSession>) {
    (Object as any).assign(this, init);
  }
}

export class PaymentPlans {
  public teamsEnabled: boolean;
  public partsLibraryEnabled: boolean;
  public reportsEnabled: boolean;
  public includedTeamMembers: number;
  public maxPlanExports: number;
  public maxSavedNestProjects: number;
  public maxMbFileSize: number;
  public subscriptionPlanId: string;
  public allowanceMinutes: number;
  public isRecurring: boolean;
  public isGrandfathered: boolean;
  public isRetired: boolean;
  public isInitialPlan: boolean;
  public overridePlanId: string;
  public overrideName: string;
  // @Ignore()
  public showToUser: boolean;

  public hasPopularFlag: boolean;
  public order: number;
  public planType: number;
  public description: string;

  public constructor(init?: Partial<PaymentPlans>) {
    (Object as any).assign(this, init);
  }
}

export class ChartLongDataEntry {
  public date: string;
  public value: number;

  public constructor(init?: Partial<ChartLongDataEntry>) {
    (Object as any).assign(this, init);
  }
}

export class ChartDoubleDataEntry {
  public date: string;
  public value: number;

  public constructor(init?: Partial<ChartDoubleDataEntry>) {
    (Object as any).assign(this, init);
  }
}

export class MaintenanceModeLog {
  public onOrOff: boolean;
  public id: string;
  public dateSent: string;
  public currentMessage: string;
  public triggeredBy: string;

  public constructor(init?: Partial<MaintenanceModeLog>) {
    (Object as any).assign(this, init);
  }
}

export class AnnouncementEmailsLogs {
  public id: string;
  public dateSent: string;
  public sender: string;
  public htmlContent: string;
  public plainTextContent: string;
  public subject: string;

  public constructor(init?: Partial<AnnouncementEmailsLogs>) {
    (Object as any).assign(this, init);
  }
}

export class AdminNestingProjectData {
  public userFirstName: string;
  public userSurname: string;
  public nestProjectEntityName: string;
  public nestProjectEntityCreatedDate: string;
  public nestingProjectId: string;
  public teamId: string;
  public createdById: string;
  public runId: string;
  public createdDate: string;
  public startedDate?: string;
  public workerReceivedDate?: string;
  public userInformedDate?: string;
  public failedDate?: string;
  public finishedDate?: string;
  public allocatedSeconds: number;
  public cancelled: boolean;
  public efficiency?: number;
  public totalPartsToNest?: number;
  public totalPartsNested?: number;
  public sheetCount?: number;
  public uniqueNests?: number;
  public sheetUsedCount?: number;

  public constructor(init?: Partial<AdminNestingProjectData>) {
    (Object as any).assign(this, init);
  }
}

export class OverviewStats {
  public errors: number;
  public successes: number;
  public assigned: number;
  public totalCompleted: number;
  public errorPercentage: number;
  public successPercentage: number;

  public constructor(init?: Partial<OverviewStats>) {
    (Object as any).assign(this, init);
  }
}

export class NestingCounts {
  public totalNests: number;
  public nestingProjectsCreated: number;
  public failedNests: number;
  public failurePercentage: number;

  public constructor(init?: Partial<NestingCounts>) {
    (Object as any).assign(this, init);
  }
}

export class UserBasics {
  public joinedTeamDate: string;
  public teamName: string;
  public teamId: string;
  public deleteAfter?: string;
  public pickedUpForDeletionDate?: string;
  public displayName: string;
  public id: string;
  public firstName: string;
  public surname: string;
  public createdDate: string;
  public locked: boolean;
  public hasActivePlan: boolean;
  public percentageOfPlanRemaining: number;
  public planLastPayment?: string;
  public planValidUntil?: string;
  public signUpTime: string;
  public lastLoggedInUntil?: string;
  public currentlyLoggedIn: boolean;
  public useDarkMode: boolean;
  public allTimeNestingCounts: NestingCounts;

  public constructor(init?: Partial<UserBasics>) {
    (Object as any).assign(this, init);
  }
}

export enum ErrorTypes {
  Unknown = 0,
  FailureCacheMiss = 1,
  ImportFileFailure = 2,
  Nesting = 3,
  ImporterMissingParts = 4,
  NegativeFeedback = 5,
  NeutralFeedback = 6,
  PositiveFeedback = 7,
  Feedback = 8,
  Paddle = 9,
  SendToSupport = 10,
  Export = 11,
  MalformedJson = 12,
  MissingJson = 13,
  NestProjectConversionIssue = 14,
  NestProjectOpenIssue = 15,
  MissingPartJson = 16,
  UserMarkAsDeletion = 17,
  Crm = 18,
  TeamDisbandment = 19,
  ServerReboot = 998,
  UserDeletions = 999,
}

export class FailureData {
  public id: string;
  public userId: string;
  public occurred: string;
  public reason: string;
  public typeOfError: ErrorTypes;
  public name: string;
  public exceptionType: string;
  public stackTrace: string;
  public exceptionMessage: string;
  public innerExceptionType: string;
  public innerStackTrace: string;
  public innerExceptionMessage: string;
  public signupResolution: string;
  public showDownloadLink: boolean;

  public constructor(init?: Partial<FailureData>) {
    (Object as any).assign(this, init);
  }
}

export enum TeamAction {
  Remove = 1,
  Leave = 2,
  AcceptInvitation = 3,
  DeclineInvitation = 4,
  CreateInvitation = 5,
  AdditionalSlotsPurchased = 6,
  TeamPlanCancelled = 7,
}

export class ViewableTeamAction {
  public teamId: string;
  public createdDate: string;
  public action: TeamAction;
  public message: string;

  public constructor(init?: Partial<ViewableTeamAction>) {
    (Object as any).assign(this, init);
  }
}

export class TeamMember {
  public userId: string;
  public name: string;

  public constructor(init?: Partial<TeamMember>) {
    (Object as any).assign(this, init);
  }
}

export class UserMetaData {
  public id: string;
  public email: string;
  public displayName: string;
  public createdDate?: string;

  public constructor(init?: Partial<UserMetaData>) {
    (Object as any).assign(this, init);
  }
}

export class PlanMetaData {
  public hasActivePlan: boolean;
  public subscriptionLastPayment?: string;
  public subscriptionValidTill?: string;
  public planName: string;
  public totalMinutes?: number;
  public remainingSeconds: number;
  public currentPlanNestStats: NestingCounts;

  public constructor(init?: Partial<PlanMetaData>) {
    (Object as any).assign(this, init);
  }
}

export class UserErrors {
  public id: string;
  public errorType: number;

  public constructor(init?: Partial<UserErrors>) {
    (Object as any).assign(this, init);
  }
}

export class FailedNestingSessionMetaData {
  public id: string;
  public userId: string;
  public dateFailed: string;
  public createdDate: string;

  public constructor(init?: Partial<FailedNestingSessionMetaData>) {
    (Object as any).assign(this, init);
  }
}

export class Teams {
  // @Required()
  public id: string;

  // @Required()
  public name: string;

  public trialStartedDated?: string;
  public freeUntil?: string;
  // @Ignore()
  public hasHadTrial: boolean;

  public maxUsersOnTeam: number;

  public constructor(init?: Partial<Teams>) {
    (Object as any).assign(this, init);
  }
}

export class NestingUsageStats {
  public userId?: string;
  public teamId?: string;
  public counts: NestingCounts;
  public usedAllowance: number;

  public constructor(init?: Partial<NestingUsageStats>) {
    (Object as any).assign(this, init);
  }
}

export enum CSVAlertType {
  Warning = 1,
  Error = 2,
}

export class CSVRowContentAlert {
  public rowNumber: number;
  public content: string;
  public message: string;
  public type: CSVAlertType;

  public constructor(init?: Partial<CSVRowContentAlert>) {
    (Object as any).assign(this, init);
  }
}

export class MemberStatus {
  public id: string;
  public email: string;
  public pending: boolean;
  public dateAdded?: string;

  public constructor(init?: Partial<MemberStatus>) {
    (Object as any).assign(this, init);
  }
}

export class UserTeamInvitations {
  public id: string;
  public senderName: string;
  public senderEmail: string;
  public recieverEmail: string;
  public teamId: string;
  public associatedUserId?: string;
  public createdDate: string;
  public acceptedDate?: string;
  public declinedDate?: string;
  public recieverEmailHash: string;

  public constructor(init?: Partial<UserTeamInvitations>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseError {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public fieldName: string;

  // @DataMember(Order=3)
  public message: string;

  // @DataMember(Order=4)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseError>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class ResponseStatus {
  // @DataMember(Order=1)
  public errorCode: string;

  // @DataMember(Order=2)
  public message: string;

  // @DataMember(Order=3)
  public stackTrace: string;

  // @DataMember(Order=4)
  public errors: ResponseError[];

  // @DataMember(Order=5)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<ResponseStatus>) {
    (Object as any).assign(this, init);
  }
}

export class Placement2D {
  public x: number;
  public y: number;
  public mirror: boolean;
  public angle: number;
  public partIdentity: string;

  public constructor(init?: Partial<Placement2D>) {
    (Object as any).assign(this, init);
  }
}

export class ImportSessionResult {
  public sessionId: string;
  public settings: UserSettings;
  public maxFileSizeInMb: number;

  public constructor(init?: Partial<ImportSessionResult>) {
    (Object as any).assign(this, init);
  }
}

export class FinaliseSessionResult {
  public nestingParts: NestingPart[];

  public constructor(init?: Partial<FinaliseSessionResult>) {
    (Object as any).assign(this, init);
  }
}

export class FileUploadResult {
  public fileId: string;
  public fileReference: string;
  public error: string;

  public constructor(init?: Partial<FileUploadResult>) {
    (Object as any).assign(this, init);
  }
}

export class FileUpdatedResult {
  public fileId: string;
  public settings: FileChangeableSettings;

  public constructor(init?: Partial<FileUpdatedResult>) {
    (Object as any).assign(this, init);
  }
}

export class FileDeleteResult {
  public wasSuccessful: boolean;
  public errorMessage: string;
  public deletedTime: string;

  public constructor(init?: Partial<FileDeleteResult>) {
    (Object as any).assign(this, init);
  }
}

export class ModelUpdateResult {
  public successful: boolean;

  public constructor(init?: Partial<ModelUpdateResult>) {
    (Object as any).assign(this, init);
  }
}

export class ModelRetrievedResult {
  public uiIdentifier: string;
  public base64SVG: string;
  public layers: IdentifiableLayer[];
  public sessionId: string;
  public fileId: string;

  public constructor(init?: Partial<ModelRetrievedResult>) {
    (Object as any).assign(this, init);
  }
}

export class ResultRetrievedResult {
  public result: IdentifiableImportResult;

  public constructor(init?: Partial<ResultRetrievedResult>) {
    (Object as any).assign(this, init);
  }
}

export class SourceFilesList {
  public files: SourceFile[];

  public constructor(init?: Partial<SourceFilesList>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteSourceFileResult {
  public success: boolean;

  public constructor(init?: Partial<DeleteSourceFileResult>) {
    (Object as any).assign(this, init);
  }
}

export class ExistingPartNames {
  public names: string[];

  public constructor(init?: Partial<ExistingPartNames>) {
    (Object as any).assign(this, init);
  }
}

export class DeletedPartResult {
  public success: boolean;

  public constructor(init?: Partial<DeletedPartResult>) {
    (Object as any).assign(this, init);
  }
}

export class PartImages {
  public base64Svg: string;
  public partId: string;

  public constructor(init?: Partial<PartImages>) {
    (Object as any).assign(this, init);
  }
}

export class SearchedLibraryPart extends MNOPart {
  public sourceFileId: string;
  public createdDate: string;
  public createdByUserId: string;
  public folderId?: string;

  public constructor(init?: Partial<SearchedLibraryPart>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class PartSearchResults {
  public idempotencyToken: string;
  public parts: SearchedLibraryPart[];
  public total: number;

  public constructor(init?: Partial<PartSearchResults>) {
    (Object as any).assign(this, init);
  }
}

export class FolderDetails {
  public id: string;
  public name: string;
  public parentId?: string;

  public constructor(init?: Partial<FolderDetails>) {
    (Object as any).assign(this, init);
  }
}

export class FolderDeletedStatus {
  public success: boolean;

  public constructor(init?: Partial<FolderDeletedStatus>) {
    (Object as any).assign(this, init);
  }
}

export class FolderMovedStatus {
  public success: boolean;

  public constructor(init?: Partial<FolderMovedStatus>) {
    (Object as any).assign(this, init);
  }
}

export class LibraryPart extends SearchedLibraryPart {
  public base64PartDefinition: string;
  public name: string;

  public constructor(init?: Partial<LibraryPart>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class OptionsForUsageQuestions {
  public materialsOptions: UsageOption[];
  public machinesOptions: UsageOption[];
  public usageOptions: UsageOption[];

  public constructor(init?: Partial<OptionsForUsageQuestions>) {
    (Object as any).assign(this, init);
  }
}

export class OptionSubmissionComplete {
  public constructor(init?: Partial<OptionSubmissionComplete>) {
    (Object as any).assign(this, init);
  }
}

export class DarkModeSettings {
  public isDarkMode: boolean;

  public constructor(init?: Partial<DarkModeSettings>) {
    (Object as any).assign(this, init);
  }
}

export class UserNestingSessionSettingsResponse {
  public settings: NestingSetting[];

  public constructor(init?: Partial<UserNestingSessionSettingsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class UserSettingsResponse {
  public availableReportSizesToTheUser: ReportSizes[];
  public maxFilesizeMb: number;
  public dxfOptions: DxfVersionValues[];
  public areaMeasurementUnits: AreaMeasurementUnits;
  public reportWithAllExports: boolean;
  public pdfReportPageSize: ReportSizes;
  public maxFileUploads: number;
  public initialSetupComplete: boolean;
  public autoCadSettings: AutoCadExportSettings;
  public nestingSettings: NestExportSettings;
  public partSettings: PartExportSettings;
  public defaultNamingScheme: NamingScheme;
  public measurementUnits: MeasurementUnits;
  public importBlocksIndependently: boolean;
  public panZoomMode: number;
  public defaultPartRotationId: number;
  public defaultPartSpacingDisplay: number;
  public defaultSheetSpacingDisplay: number;
  public defaultSheetQuantity: number;
  public mirrorDefault: boolean;
  public defaultTilt: number;
  public labelHeightDisplay: number;
  public defaultAllowedRotations: Rotations;
  public nestingDirection: NestingDirectionSetting;
  public nestingRepeat: RepeatOption;
  public defaultTeamSheets: TeamSheets[];
  public useSeparateSheetSpacing: boolean;
  public defaultLeftSheetSpacingDisplay: number;
  public defaultRightSheetSpacingDisplay: number;
  public defaultTopSheetSpacingDisplay: number;
  public defaultBottomSheetSpacingDisplay: number;

  public constructor(init?: Partial<UserSettingsResponse>) {
    (Object as any).assign(this, init);
  }
}

export class UserProfile {
  public profileData: Profile;
  public teamPlan: TeamPlanDetails;

  public constructor(init?: Partial<UserProfile>) {
    (Object as any).assign(this, init);
  }
}

export class NestResultSummary {
  public nestCount: number;
  public nestSheetCount: number;
  public isFinal: boolean;
  public nestedPartCount: number;
  public efficiency: number;
  public nestLengthMM: number;
  public nestLengthDisplay: number;
  public unplacedPartCounts: { [index: string]: number };
  public sheetDetails: SheetDetail[];
  public id: string;

  public constructor(init?: Partial<NestResultSummary>) {
    (Object as any).assign(this, init);
  }
}

export class NestingProject extends SearchedNestingProject {
  public version: number;
  public lastUpdatedById?: string;
  public parts: NestingPart[];
  public sheets: NestingSheet[];
  public nestSettings: NestSettings;
  public currentSettingsHasBeenNested: boolean;
  public lastOrDefinitiveResultSummary: NestResultSummary;
  public results: NestingResult[];
  public lastRunId?: string;
  public useSeparateSheetEdgeSpacing: boolean;
  public hasBeenCancelled: boolean;

  public constructor(init?: Partial<NestingProject>) {
    super(init);
    (Object as any).assign(this, init);
  }
}

export class NestingAllowanceAvailable {
  public hasEnoughAllowanceToNest: boolean;

  public constructor(init?: Partial<NestingAllowanceAvailable>) {
    (Object as any).assign(this, init);
  }
}

export class NestingProjectRunResponse {
  public hasNestRunningAlready: boolean;
  public hasEnoughAllowance: boolean;
  public projectId: string;
  public runId: string;
  public createdById: string;
  public createdDate: string;
  public queuePlace: number;

  public constructor(init?: Partial<NestingProjectRunResponse>) {
    (Object as any).assign(this, init);
  }
}

export class CancelNestingSessionResponse {
  public responseSent: string;

  public constructor(init?: Partial<CancelNestingSessionResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DeleteNestingSessionResponse {
  public success: boolean;

  public constructor(init?: Partial<DeleteNestingSessionResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SearchResults {
  public idempotencyToken: string;
  public results: SearchedNestingProject[];
  public offset: number;
  public total: number;

  public constructor(init?: Partial<SearchResults>) {
    (Object as any).assign(this, init);
  }
}

export class SuccessCheck {
  public success: boolean;

  public constructor(init?: Partial<SuccessCheck>) {
    (Object as any).assign(this, init);
  }
}

export class RestoreResponse {
  public id: string;

  public constructor(init?: Partial<RestoreResponse>) {
    (Object as any).assign(this, init);
  }
}

export class AuthenticationUris implements IAuthenticationUris {
  public loginUri: string;
  public signUpUri: string;
  public editProfileUri: string;
  public logoutUri: string;
  public resetPasswordUri: string;
  public refreshUri: string;

  public constructor(init?: Partial<AuthenticationUris>) {
    (Object as any).assign(this, init);
  }
}

export class PathToSignUpUri {
  public signUpUri: string;

  public constructor(init?: Partial<PathToSignUpUri>) {
    (Object as any).assign(this, init);
  }
}

export class CallbackResponse {
  public success: boolean;

  public constructor(init?: Partial<CallbackResponse>) {
    (Object as any).assign(this, init);
  }
}

export class VersionResponse {
  public time: string;
  public version: number;
  public configId: string;

  public constructor(init?: Partial<VersionResponse>) {
    (Object as any).assign(this, init);
  }
}

export class AliveResponse {
  public time: string;
  public config: string;

  public constructor(init?: Partial<AliveResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TrailRequestResponse {
  public successful: boolean;

  public constructor(init?: Partial<TrailRequestResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PaymentModificationResult {
  public orderId: string;
  public success: boolean;
  public errorMessage: string;

  public constructor(init?: Partial<PaymentModificationResult>) {
    (Object as any).assign(this, init);
  }
}

export class AccountDetails {
  public isActive: boolean;
  public accountState: AccountStates;
  public mnoPlanName: string;
  public activeUntil?: string;
  public freeUntil?: string;
  public isRecurring: boolean;
  public nextBillingDate?: string;
  public paymentDetails: PaymentInformation;
  public lastOrderId: string;
  public paymentProviderUpdatePaymentURL: string;
  public paymentProviderPlanId: string;
  public paymentProviderSubscriptionId: string;
  public canUpgradeToPlans: string[];
  public planType: PaymentPlanTypes;

  public constructor(init?: Partial<AccountDetails>) {
    (Object as any).assign(this, init);
  }
}

export class ReceiptResponse {
  public receipts: PaymentReceipt[];
  public total: number;

  public constructor(init?: Partial<ReceiptResponse>) {
    (Object as any).assign(this, init);
  }
}

export class DiscountResponse {
  public discount: number;

  public constructor(init?: Partial<DiscountResponse>) {
    (Object as any).assign(this, init);
  }
}

export class CustomCheckout {
  public url: string;

  public constructor(init?: Partial<CustomCheckout>) {
    (Object as any).assign(this, init);
  }
}

export class AvailablePlansData {
  public userIsAwaitingPaymentlessTrial: boolean;
  public plans: PlanBasicData[];

  public constructor(init?: Partial<AvailablePlansData>) {
    (Object as any).assign(this, init);
  }
}

export class PassThroughData {
  public data: string;

  public constructor(init?: Partial<PassThroughData>) {
    (Object as any).assign(this, init);
  }
}

export class TermsAndConditionsContent {
  public id: number;
  public content: string;
  public publishedDate: string;

  public constructor(init?: Partial<TermsAndConditionsContent>) {
    (Object as any).assign(this, init);
  }
}

export class TermsAccepted {
  public success: boolean;
  public reason: string;

  public constructor(init?: Partial<TermsAccepted>) {
    (Object as any).assign(this, init);
  }
}

export class UserTermsStatus {
  public currentlyAcceptedTerms?: number;
  public hasAcceptedCurrentTerms: boolean;

  public constructor(init?: Partial<UserTermsStatus>) {
    (Object as any).assign(this, init);
  }
}

export class FeedbackResponse {
  public success: boolean;

  public constructor(init?: Partial<FeedbackResponse>) {
    (Object as any).assign(this, init);
  }
}

export class PaddleMetaData {
  public id: string;
  public name: string;
  public isDayPass: boolean;
  public allowance: PaymentPlans;

  public constructor(init?: Partial<PaddleMetaData>) {
    (Object as any).assign(this, init);
  }
}

export class FetchedAllowances {
  public planAllowances: PaddleMetaData[];

  public constructor(init?: Partial<FetchedAllowances>) {
    (Object as any).assign(this, init);
  }
}

export class AzureLinks {
  public links: { [index: string]: string };

  public constructor(init?: Partial<AzureLinks>) {
    (Object as any).assign(this, init);
  }
}

export class NestCountChartData {
  public labels: string[];
  public ranNests: ChartLongDataEntry[];
  public ranNestsValues: number[];
  public successfulNests: ChartLongDataEntry[];
  public successfulNestsValues: number[];
  public failedNests: ChartLongDataEntry[];
  public failedNestsValues: number[];
  public assignedNests: ChartLongDataEntry[];
  public assignedNestsValues: number[];

  public constructor(init?: Partial<NestCountChartData>) {
    (Object as any).assign(this, init);
  }
}

export class QueueLengthChartData {
  public labels: string[];
  public minimumSecondsToAgentAssign: ChartDoubleDataEntry[];
  public minimumSecondsToAssignValues: number[];
  public maximumSecondsToAgentAssign: ChartDoubleDataEntry[];
  public maximumSecondsToAssignValues: number[];
  public averageSecondsToAgentAssign: ChartDoubleDataEntry[];
  public averageSecondsToAssignValues: number[];
  public queueLength: ChartLongDataEntry[];
  public queueLengthValues: number[];
  public abandonQueueLength: ChartLongDataEntry[];
  public abandonQueueLengthValues: number[];
  public redisRenderFailQueue: ChartLongDataEntry[];
  public redisRenderFailQueueValues: number[];
  public redisErrorQueue: ChartLongDataEntry[];
  public redisErrorQueueValues: number[];

  public constructor(init?: Partial<QueueLengthChartData>) {
    (Object as any).assign(this, init);
  }
}

export class LoggedInUserCountsChart {
  public labels: string[];
  public loginDataStats: ChartLongDataEntry[];
  public loginDataStatsValues: number[];

  public constructor(init?: Partial<LoggedInUserCountsChart>) {
    (Object as any).assign(this, init);
  }
}

export class FeatureSetting {
  public isEnabled: boolean;
  public currentMessage: string;
  public ttl?: string;

  public constructor(init?: Partial<FeatureSetting>) {
    (Object as any).assign(this, init);
  }
}

export class MaintenanceLog {
  public log: MaintenanceModeLog[];

  public constructor(init?: Partial<MaintenanceLog>) {
    (Object as any).assign(this, init);
  }
}

export class EmailLog {
  public log: AnnouncementEmailsLogs[];

  public constructor(init?: Partial<EmailLog>) {
    (Object as any).assign(this, init);
  }
}

export class EmailMaintenancePreview {
  public htmlContent: string;
  public plainText: string;
  public subject: string;

  public constructor(init?: Partial<EmailMaintenancePreview>) {
    (Object as any).assign(this, init);
  }
}

export class EmailMaintenanceResponse {
  public emailsAttempted: number;

  public constructor(init?: Partial<EmailMaintenanceResponse>) {
    (Object as any).assign(this, init);
  }
}

export class NestingReportResponse {
  public nests: AdminNestingProjectData[];

  public constructor(init?: Partial<NestingReportResponse>) {
    (Object as any).assign(this, init);
  }
}

export class OverviewStatistics {
  public currentStatsStats: OverviewStats;
  public previousStats: OverviewStats;
  public errorTrend: number;
  public successTrend: number;

  public constructor(init?: Partial<OverviewStatistics>) {
    (Object as any).assign(this, init);
  }
}

export class LockedSuccessStatus {
  public isSuccessful: boolean;
  public lockedStatus: boolean;

  public constructor(init?: Partial<LockedSuccessStatus>) {
    (Object as any).assign(this, init);
  }
}

export class FreeAccountSuccessStatus {
  public isSuccessful: boolean;
  public freeAccountStatus: boolean;
  public failureReason: string;
  public stackTrace: string;

  public constructor(init?: Partial<FreeAccountSuccessStatus>) {
    (Object as any).assign(this, init);
  }
}

export class UserFailureData {
  public failures: FailureData[];

  public constructor(init?: Partial<UserFailureData>) {
    (Object as any).assign(this, init);
  }
}

export class GDPRAdminComplete {
  public success: boolean;
  public reason: string;

  public constructor(init?: Partial<GDPRAdminComplete>) {
    (Object as any).assign(this, init);
  }
}

export class ExtendPlanSuccessStatus {
  public isSuccessful: boolean;

  public constructor(init?: Partial<ExtendPlanSuccessStatus>) {
    (Object as any).assign(this, init);
  }
}

export class MaxFileSizeSuccessStatus {
  public isSuccessful: boolean;
  public maxFileSizeStatus: number;

  public constructor(init?: Partial<MaxFileSizeSuccessStatus>) {
    (Object as any).assign(this, init);
  }
}

export class UserProfileData {
  public teamName: string;
  public teamId: string;
  public joinedTeamDate: string;
  public maxUniqueNests: number;
  public exportLimit?: number;
  public nestingSeconds: number;
  public partCount: number;
  public totalPartCount: number;
  public importSessionCount: number;
  public timings: { [index: string]: number };
  public isFreeAccount: boolean;
  public freeUntil?: string;
  public isMarkedForDeletion: boolean;
  public deleteAfter?: string;
  public freshSalesLink: string;
  public isLocked: boolean;
  public user: UserMetaData;
  public isLoggedIn: boolean;
  public supportTickets: string;
  public maxFileUploadMb: number;
  public maxImportLineCountPerPart: number;
  public planData: PlanMetaData;
  public errors: UserErrors[];
  public allTimeNestStats: NestingCounts;
  public failedNestingSessions: FailedNestingSessionMetaData[];
  public questionnaireMaterials: string[];
  public questionnaireMachines: string[];
  public questionnaireUsage: string[];
  public lastLoggedInValidUntil?: string;
  public userAcceptedTermsAt?: string;
  public termsAcceptedVersion?: number;

  public constructor(init?: Partial<UserProfileData>) {
    (Object as any).assign(this, init);
  }
}

export class User {
  // @Ignore()
  public displayName: string;

  public joinedTeamDate: string;
  // @Required()
  public id: string;

  // @Required()
  public firstName: string;

  // @Required()
  public surname: string;

  // @Required()
  public nullableTeamId: string;

  // @Ignore()
  public teamId: string;

  public deleted: boolean;
  // @References("typeof(Efficient.MyNestingOnline.Api.DataModels.Teams)")
  public team: Teams;

  public useDarkMode: boolean;
  public locked: boolean;
  public lastTokenValidTill?: string;
  public deleteAfter?: string;
  public pickedUpForDeletionDate?: string;
  public createdDate: string;
  public userAgent: string;
  public resolution: string;
  public freshSalesId?: number;
  public hasStartedImport: boolean;
  public hasCompletedImport: boolean;
  public hasCompletedNest: boolean;
  public hasCompletedExport: boolean;
  public hasUpdatedSettings: boolean;
  public hasSubmittedParametricParts: boolean;
  public originalTeamId?: string;

  public constructor(init?: Partial<User>) {
    (Object as any).assign(this, init);
  }
}

export class GlobalSettings {
  public pdfReportPageSize: number;
  public reportWithAllExports: boolean;
  public maxLibraryParts: number;
  public maxPartFolders: number;
  public maxNestingFolders: number;
  public initialSetupComplete: boolean;
  public maxFileUploadCount: number;
  public maxFileSizeMb: number;
  public teamId: string;
  public measurementUnits: number;
  public defaultNamingScheme: number;
  public importBlocksIndependently: boolean;
  public maxSavedSessions: number;
  public panZoomMode: number;
  public defaultPartRotationId: number;
  public nestingMinimumSeconds: number;
  public rotationAllowed: number;
  public defaultSheetQuantity: number;
  public mirrorDefault: boolean;
  public defaultTilt: number;
  public labelHeightMM: number;
  // @Ignore()
  public labelHeightInches: number;

  public nestingDirectionSetting: number;
  public repeatSetting: number;
  public defaultLeftSheetSpacingMM?: number;
  public defaultRightSheetSpacingMM?: number;
  public defaultTopSheetSpacingMM?: number;
  public defaultBottomSheetSpacingMM?: number;
  public defaultPartSpacingMM?: number;
  public defaultSheetSpacingMM?: number;
  public maxImportLineCountPerPart: number;
  // @Ignore()
  public defaultLeftSheetSpacingDisplay?: number;

  // @Ignore()
  public defaultRightSheetSpacingDisplay?: number;

  // @Ignore()
  public defaultBottomSheetSpacingDisplay?: number;

  // @Ignore()
  public defaultTopSheetSpacingDisplay?: number;

  // @Ignore()
  public defaultPartSpacingDisplay: number;

  // @Ignore()
  public defaultSheetSpacingDisplay: number;

  public useSeparateSheetSpacing: boolean;
  public areaMeasurementUnits: number;
  public reportMaxNests: number;

  public constructor(init?: Partial<GlobalSettings>) {
    (Object as any).assign(this, init);
  }
}

export class TeamOverview {
  public id: string;
  public name: string;
  public members: User[];
  public nestingStats: NestingUsageStats;
  public allowedMembers: number;
  public teamMemberCount: number;
  public noteCount: number;
  public partCount: number;
  public importSessionCount: number;
  public canInvite: boolean;
  public disbandmentDate?: string;

  public constructor(init?: Partial<TeamOverview>) {
    (Object as any).assign(this, init);
  }
}

export class GDPRComplete {
  public success: boolean;
  public message: string;

  public constructor(init?: Partial<GDPRComplete>) {
    (Object as any).assign(this, init);
  }
}

export class CSVHeaderResponse {
  public headers: string[];
  public delimiter: string;
  public userAssignments: CSVHeaderAssignments;

  public constructor(init?: Partial<CSVHeaderResponse>) {
    (Object as any).assign(this, init);
  }
}

export class CSVPartResponse {
  public alerts: CSVRowContentAlert[];
  public parts: NestingPart[];

  public constructor(init?: Partial<CSVPartResponse>) {
    (Object as any).assign(this, init);
  }
}

export class SuccessResponse {
  public success: boolean;
  public errorMessage: string;

  public constructor(init?: Partial<SuccessResponse>) {
    (Object as any).assign(this, init);
  }
}

export class TeamMembersState {
  public teamId: string;
  public currentCount: number;
  public maxCount: number;
  public pendingInvitations: number;
  public teamName: string;
  public canInviteNewUser: boolean;
  public members: MemberStatus[];

  public constructor(init?: Partial<TeamMembersState>) {
    (Object as any).assign(this, init);
  }
}

export class PublicInvite {
  public id: string;
  public teamName: string;
  public inviter: string;
  public inviterEmail: string;

  public constructor(init?: Partial<PublicInvite>) {
    (Object as any).assign(this, init);
  }
}

export class DeclinedStatus {
  public success: boolean;

  public constructor(init?: Partial<DeclinedStatus>) {
    (Object as any).assign(this, init);
  }
}

export class AcceptStatus {
  public success: boolean;

  public constructor(init?: Partial<AcceptStatus>) {
    (Object as any).assign(this, init);
  }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
  // @DataMember(Order=1)
  public userId: string;

  // @DataMember(Order=2)
  public sessionId: string;

  // @DataMember(Order=3)
  public userName: string;

  // @DataMember(Order=4)
  public displayName: string;

  // @DataMember(Order=5)
  public referrerUrl: string;

  // @DataMember(Order=6)
  public bearerToken: string;

  // @DataMember(Order=7)
  public refreshToken: string;

  // @DataMember(Order=8)
  public profileUrl: string;

  // @DataMember(Order=9)
  public roles: string[];

  // @DataMember(Order=10)
  public permissions: string[];

  // @DataMember(Order=11)
  public responseStatus: ResponseStatus;

  // @DataMember(Order=12)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<AuthenticateResponse>) {
    (Object as any).assign(this, init);
  }
}

// @Route("/ImportSession/KeepAlive", "PUT")
export class KeepSessionAlive implements IReturn<ImportSessionResult>, IPost {
  public sessionId: string;

  public constructor(init?: Partial<KeepSessionAlive>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "KeepSessionAlive";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new ImportSessionResult();
  }
}

// @Route("/ImportSession/", "GET")
export class GetAssignmentTypes implements IReturn<Assignment[]>, IGet {
  public constructor(init?: Partial<GetAssignmentTypes>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetAssignmentTypes";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<Assignment>();
  }
}

// @Route("/ImportSession/", "POST")
export class CreateImportSession
  implements IReturn<ImportSessionResult>, IPost
{
  public constructor(init?: Partial<CreateImportSession>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CreateImportSession";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new ImportSessionResult();
  }
}

// @Route("/ImportSession/Cancel", "DELETE")
export class CancelSessionRequest implements IDelete {
  public sessionId: string;

  public constructor(init?: Partial<CancelSessionRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CancelSessionRequest";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {}
}

// @Route("/ImportSession/Complete", "PUT")
export class CompleteSessionRequest
  implements IReturn<FinaliseSessionResult>, IPut
{
  public folderId?: string;
  public sessionId: string;
  public fileOptions: CompletedSessionFileOptions[];
  public isOnFlyModeSession: boolean;

  public constructor(init?: Partial<CompleteSessionRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CompleteSessionRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FinaliseSessionResult();
  }
}

// @Route("/File", "POST")
export class CreateFileSettings
  implements IReturn<FileUploadResult>, IPost, ITraceableRequest
{
  public file: string;
  public fileName: string;
  public sessionId: string;
  public fileReference: string;
  public uniqueRequestId: string;

  public constructor(init?: Partial<CreateFileSettings>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CreateFileSettings";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FileUploadResult();
  }
}

// @Route("/importsession/global", "Put")
export class UpdateImporterGlobalSettings {
  public sessionId: string;

  public constructor(init?: Partial<UpdateImporterGlobalSettings>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdateImporterGlobalSettings";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/File", "Put")
export class UpdateFileSettings
  implements IReturn<FileUpdatedResult>, IPut, ITraceableRequest
{
  public fileId: string;
  public sessionId: string;
  public fileSettings: FileChangeableSettings;
  public uniqueRequestId: string;

  public constructor(init?: Partial<UpdateFileSettings>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdateFileSettings";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FileUpdatedResult();
  }
}

// @Route("/File", "DELETE")
export class DeleteFileIdentity implements IReturn<FileDeleteResult>, IPost {
  public fileId: string;
  public sessionId: string;

  public constructor(init?: Partial<DeleteFileIdentity>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteFileIdentity";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FileDeleteResult();
  }
}

// @Route("/Model", "PUT")
export class UpdateModelSettings
  implements IReturn<ModelUpdateResult>, IPut, ITraceableRequest
{
  public sessionId: string;
  public fileId: string;
  public layerChanges: IdentifiableLayer[];
  public uniqueRequestId: string;
  public groupPartAsDrawn: boolean;

  public constructor(init?: Partial<UpdateModelSettings>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdateModelSettings";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new ModelUpdateResult();
  }
}

// @Route("/Model", "GET")
export class RetrieveModelIdentity
  implements IReturn<ModelRetrievedResult>, IGet
{
  public fileId: string;
  public sessionId: string;

  public constructor(init?: Partial<RetrieveModelIdentity>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RetrieveModelIdentity";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new ModelRetrievedResult();
  }
}

// @Route("/Result", "GET")
export class RetrieveResultIdentity
  implements IReturn<ResultRetrievedResult>, IGet
{
  public fileId: string;
  public sessionId: string;
  public uniqueRequestId: string;

  public constructor(init?: Partial<RetrieveResultIdentity>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RetrieveResultIdentity";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new ResultRetrievedResult();
  }
}

// @Route("/SourceFiles", "GET")
export class SourceFilesRequest implements IReturn<Blob>, IGet {
  public fileIds: string[];

  public constructor(init?: Partial<SourceFilesRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SourceFilesRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/SourceFile", "GET")
export class SourceFileRequest implements IReturn<Blob>, IGet {
  public fileId: string;

  public constructor(init?: Partial<SourceFileRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SourceFileRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/SourceFile/List", "GET")
export class SourceFileListRequest implements IReturn<SourceFilesList>, IGet {
  public constructor(init?: Partial<SourceFileListRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SourceFileListRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new SourceFilesList();
  }
}

// @Route("/SourceFile", "DELETE")
export class DeleteSourceFileRequest
  implements IReturn<DeleteSourceFileResult>, IDelete
{
  public fileId: string;

  public constructor(init?: Partial<DeleteSourceFileRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteSourceFileRequest";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new DeleteSourceFileResult();
  }
}

// @Route("/Parts/GetPartGeometry", "GET")
export class GetPartGeometryForIds implements IReturn<PartGeometries[]>, IGet {
  public ids: string[];

  public constructor(init?: Partial<GetPartGeometryForIds>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetPartGeometryForIds";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<PartGeometries>();
  }
}

// @Route("/Parts/Existing", "POST")
export class CheckIfPartNamesExist implements IReturn<ExistingPartNames> {
  public names: string[];
  public folderId?: string;

  public constructor(init?: Partial<CheckIfPartNamesExist>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CheckIfPartNamesExist";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new ExistingPartNames();
  }
}

// @Route("/Part", "DELETE")
export class DeletePart implements IReturn<DeletedPartResult> {
  public partId: string;

  public constructor(init?: Partial<DeletePart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeletePart";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new DeletedPartResult();
  }
}

// @Route("/Part", "DELETE")
export class DeleteParts implements IReturn<DeletedPartResult> {
  public partIds: string[];

  public constructor(init?: Partial<DeleteParts>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteParts";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new DeletedPartResult();
  }
}

// @Route("/Part/Images", "GET")
export class GetImagesForPart implements IReturn<PartImages>, IGet {
  public partId: string;
  public svg: boolean;

  public constructor(init?: Partial<GetImagesForPart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetImagesForPart";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new PartImages();
  }
}

// @Route("/Part/ParametricParts", "POST")
export class ParametricPartsRequest implements IReturn<NestingPart[]>, IPost {
  public parts: ParametricPartDefinition[];

  public constructor(init?: Partial<ParametricPartsRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ParametricPartsRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new Array<NestingPart>();
  }
}

// @Route("/Parts/search", "POST")
export class SearchPart implements IReturn<PartSearchResults> {
  public idempotencyToken: string;
  public folderId?: string;
  public searchType: FolderSearchType;
  public returnSmallPngs: boolean;
  public skip: number;
  public take: number;
  public orderBy: string;
  public direction: string;
  public searchParams: PartSearchParameter[];

  public constructor(init?: Partial<SearchPart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SearchPart";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PartSearchResults();
  }
}

// @Route("/Part/Clone", "POST")
export class ClonePartRequest implements IReturn<SearchedLibraryPart>, IGet {
  public partId: string;

  public constructor(init?: Partial<ClonePartRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ClonePartRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new SearchedLibraryPart();
  }
}

// @Route("/Part/Folder/Hierarchy", "GET")
export class GetPartsFolderHierarchy
  implements IReturn<FolderHierarchy[]>, IGet
{
  public constructor(init?: Partial<GetPartsFolderHierarchy>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetPartsFolderHierarchy";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<FolderHierarchy>();
  }
}

// @Route("/Part/Folder", "POST")
export class AddFolderRequest implements IReturn<FolderDetails> {
  public folderName: string;
  public parentFolderId?: string;

  public constructor(init?: Partial<AddFolderRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AddFolderRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FolderDetails();
  }
}

// @Route("/Part/Folder/", "PUT")
export class RenameFolderRequest implements IReturn<FolderDetails> {
  public folderName: string;
  public id: string;

  public constructor(init?: Partial<RenameFolderRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RenameFolderRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FolderDetails();
  }
}

// @Route("/Part/Folder/", "DELETE")
export class DeleteFolderRequest implements IReturn<FolderDeletedStatus> {
  public id: string;
  public deletePartsInFolder: boolean;

  public constructor(init?: Partial<DeleteFolderRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteFolderRequest";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new FolderDeletedStatus();
  }
}

// @Route("/Part/MoveFolder/", "PUT")
export class MoveFolderRequest implements IReturn<FolderMovedStatus> {
  public partIds: string[];
  public moveToFolderId?: string;

  public constructor(init?: Partial<MoveFolderRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MoveFolderRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FolderMovedStatus();
  }
}

// @Route("/Part/Rotate", "PUT")
export class RotatePartRequest implements IReturn<LibraryPart>, IPost {
  public sourcePartId: string;
  public rotationAngle: number;

  public constructor(init?: Partial<RotatePartRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RotatePartRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new LibraryPart();
  }
}

// @Route("/Part/Mirror", "PUT")
export class MirrorPartRequest implements IReturn<LibraryPart> {
  public sourcePartId: string;
  public mirrorDirection: MirrorDirection;

  public constructor(init?: Partial<MirrorPartRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MirrorPartRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new LibraryPart();
  }
}

// @Route("/Part", "PUT")
export class UpdatePartBasics implements IReturn<LibraryPart>, IPut {
  public libraryId: string;
  public name: string;

  public constructor(init?: Partial<UpdatePartBasics>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdatePartBasics";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new LibraryPart();
  }
}

// @Route("/Team/Part", "GET")
export class FetchTeamParts implements IReturn<SearchedLibraryPart[]>, IGet {
  public constructor(init?: Partial<FetchTeamParts>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchTeamParts";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<SearchedLibraryPart>();
  }
}

// @Route("/Part", "GET")
export class FetchPart implements IReturn<SearchedLibraryPart>, IGet {
  public partId: string;

  public constructor(init?: Partial<FetchPart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchPart";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new SearchedLibraryPart();
  }
}

// @Route("/Part", "POST")
export class CreatePart implements IReturn<LibraryPart> {
  public folderId?: string;
  public name: string;
  public type: LibraryType;
  public createCircle: CreateCircle;
  public createRectangle: CreateRectangle;
  public createRing: CreateRing;

  public constructor(init?: Partial<CreatePart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CreatePart";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new LibraryPart();
  }
}

// @Route("/test/ResetSettings", "Post")
export class ResetUserSettingsToDefault implements IPost {
  public constructor(init?: Partial<ResetUserSettingsToDefault>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ResetUserSettingsToDefault";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/test/ClearTestAccount", "Post")
export class TestClearSession implements IPost {
  public constructor(init?: Partial<TestClearSession>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "TestClearSession";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/Questions/Usage", "GET")
export class GetOptionsForUsageQuestions
  implements IReturn<OptionsForUsageQuestions>, IGet
{
  public constructor(init?: Partial<GetOptionsForUsageQuestions>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetOptionsForUsageQuestions";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new OptionsForUsageQuestions();
  }
}

// @Route("/Questions/Usage", "POST")
export class SubmitOptionsForUsageQuestions
  implements IReturn<OptionSubmissionComplete>, IPost
{
  public materialsOptions: UsageOption[];
  public machinesOptions: UsageOption[];
  public usageOptions: UsageOption[];
  public userAgentString: string;
  public resolution: string;

  public constructor(init?: Partial<SubmitOptionsForUsageQuestions>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SubmitOptionsForUsageQuestions";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new OptionSubmissionComplete();
  }
}

// @Route("/user/darkmode", "GET")
export class GetDarkModeForUser implements IReturn<DarkModeSettings>, IGet {
  public constructor(init?: Partial<GetDarkModeForUser>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetDarkModeForUser";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new DarkModeSettings();
  }
}

// @Route("/user/darkmode", "PUT")
export class UpdateDarkModeForUser implements IReturn<DarkModeSettings>, IGet {
  public isDarkMode: boolean;

  public constructor(init?: Partial<UpdateDarkModeForUser>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdateDarkModeForUser";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new DarkModeSettings();
  }
}

// @Route("/NestingAvailabileSettings", "GET")
export class UserNestingSessionSettingsRequest
  implements IReturn<UserNestingSessionSettingsResponse>, IGet
{
  public constructor(init?: Partial<UserNestingSessionSettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UserNestingSessionSettingsRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserNestingSessionSettingsResponse();
  }
}

// @Route("/GlobalSettings", "GET")
export class SettingsRequest implements IReturn<UserSettingsResponse>, IGet {
  public constructor(init?: Partial<SettingsRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SettingsRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserSettingsResponse();
  }
}

// @Route("/InitialSettings", "PUT")
export class InitialSettingsUpdateRequest
  implements IReturn<UserSettingsResponse>, IPut
{
  public measurementUnits: MeasurementUnits;

  public constructor(init?: Partial<InitialSettingsUpdateRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "InitialSettingsUpdateRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new UserSettingsResponse();
  }
}

// @Route("/GlobalSettings", "PUT")
export class SettingsUpdateRequest
  extends UserSettings
  implements IReturn<UserSettingsResponse>, IPut
{
  public reportWithAllExports: boolean;
  public pdfReportPageSize: ReportSizes;
  public labelHeightDisplay?: number;
  public defaultTilt?: number;
  public autoCadSettings: AutoCadExportSettings;
  public nestingSettings: NestExportSettings;
  public partSettings: PartExportSettings;
  public nestingDirection: NestingDirectionSetting;
  public nestingRepeat: RepeatOption;
  public areaMeasurementUnits: AreaMeasurementUnits;
  public defaultTeamSheets: TeamSheets[];
  public useSeparateSheetSpacing: boolean;
  public defaultLeftSheetSpacingDisplay: number;
  public defaultRightSheetSpacingDisplay: number;
  public defaultTopSheetSpacingDisplay: number;
  public defaultBottomSheetSpacingDisplay: number;

  public constructor(init?: Partial<SettingsUpdateRequest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SettingsUpdateRequest";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new UserSettingsResponse();
  }
}

// @Route("/Account/", "GET")
export class AccountRequest implements IReturn<UserProfile>, IGet {
  public constructor(init?: Partial<AccountRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AccountRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserProfile();
  }
}

// @Route("/Nesting/ExampleProject", "Get")
export class GetExampleProject implements IReturn<NestingProject>, IGet {
  public projectType: ExampleProjectTypes;

  public constructor(init?: Partial<GetExampleProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetExampleProject";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestingProject();
  }
}

// @Route("/Nesting/RotateOrMirrorParts", "POST")
export class RotateOrMirrorParts
  implements IReturn<RotatedNestingPart[]>, IPost
{
  public parts: NestingPart[];
  public rotation?: number;
  public mirror: MirrorDirection;

  public constructor(init?: Partial<RotateOrMirrorParts>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RotateOrMirrorParts";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new Array<RotatedNestingPart>();
  }
}

// @Route("/Nesting/GeometryForSheet", "POST")
export class GeometryForSheetRequest
  implements IReturn<GeometryForSheet[]>, IPost
{
  public sheetSettings: SheetGeometrySettings[];

  public constructor(init?: Partial<GeometryForSheetRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GeometryForSheetRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new Array<GeometryForSheet>();
  }
}

// @Route("/NestingProject/CheckAllowance", "GET")
export class CheckForNestingAllowance
  implements IReturn<NestingAllowanceAvailable>
{
  public constructor(init?: Partial<CheckForNestingAllowance>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CheckForNestingAllowance";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestingAllowanceAvailable();
  }
}

// @Route("/NestingProject/notes", "GET")
export class NotesForProject implements IReturn<NoteDetails[]>, IGet {
  public projectId: string;

  public constructor(init?: Partial<NotesForProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NotesForProject";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<NoteDetails>();
  }
}

// @Route("/NestingProject/notes", "POST")
export class AddNotesToProject implements IReturn<NoteDetails[]>, IPost {
  public projectId: string;
  public content: string;

  public constructor(init?: Partial<AddNotesToProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AddNotesToProject";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new Array<NoteDetails>();
  }
}

// @Route("/NestingProject/notes", "DELETE")
export class DeleteNoteFromProject implements IReturn<NoteDetails[]>, IDelete {
  public projectId: string;
  public noteId: string;

  public constructor(init?: Partial<DeleteNoteFromProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteNoteFromProject";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new Array<NoteDetails>();
  }
}

// @Route("/NestingProject/Run", "POST")
export class NestingProjectStartNest
  extends NestingProject
  implements IReturn<NestingProjectRunResponse>
{
  public constructor(init?: Partial<NestingProjectStartNest>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingProjectStartNest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new NestingProjectRunResponse();
  }
}

// @Route("/NestingProject/Cancel", "POST")
export class CancelNestingSession
  implements IReturn<CancelNestingSessionResponse>, IPost
{
  public sessionId: string;
  public runId: string;

  public constructor(init?: Partial<CancelNestingSession>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CancelNestingSession";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new CancelNestingSessionResponse();
  }
}

// @Route("/NestingResult", "GET")
export class FetchNestingResult implements IReturn<NestResultSummary> {
  public projectId: string;
  public resultId: string;
  public status: JobStatus;

  public constructor(init?: Partial<FetchNestingResult>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchNestingResult";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestResultSummary();
  }
}

// @Route("/NestingProject/auth", "GET")
export class SetNestingAuth {
  public constructor(init?: Partial<SetNestingAuth>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetNestingAuth";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {}
}

// @Route("/NestingProject/Details", "GET")
export class FetchFullNestingProject implements IReturn<NestingProject> {
  public projectId: string;

  public constructor(init?: Partial<FetchFullNestingProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchFullNestingProject";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestingProject();
  }
}

// @Route("/NestingProject/Save", "PUT")
export class SaveNestingProject
  extends NestingProject
  implements IReturn<NestingProject>
{
  public saveMethod: SaveOrSaveAs;

  public constructor(init?: Partial<SaveNestingProject>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SaveNestingProject";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new NestingProject();
  }
}

// @Route("/NestingProject", "DELETE")
export class DeleteNestingSession
  implements IReturn<DeleteNestingSessionResponse>
{
  public nestingSessionId: string;

  public constructor(init?: Partial<DeleteNestingSession>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteNestingSession";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new DeleteNestingSessionResponse();
  }
}

// @Route("/HistoricNestingSessions", "GET")
export class HistoricNestingSessionSearch
  extends NestSearchParams
  implements IReturn<SearchResults>, IGet
{
  public constructor(init?: Partial<HistoricNestingSessionSearch>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "HistoricNestingSessionSearch";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new SearchResults();
  }
}

// @Route("/Nesting/Folder/Move", "PUT")
export class MoveNestsIntoFolder implements IReturn<SuccessCheck> {
  public folderId?: string;
  public nestsToMove: string[];

  public constructor(init?: Partial<MoveNestsIntoFolder>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MoveNestsIntoFolder";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new SuccessCheck();
  }
}

// @Route("/Nesting/Folder/Hierarchy", "GET")
export class GetNestingFolderHierarchy
  implements IReturn<FolderHierarchy[]>, IGet
{
  public constructor(init?: Partial<GetNestingFolderHierarchy>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetNestingFolderHierarchy";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<FolderHierarchy>();
  }
}

// @Route("/Nesting/Folder", "POST")
export class CreateNestingFolder implements IReturn<FolderDetails> {
  public folderName: string;
  public parentFolderId?: string;

  public constructor(init?: Partial<CreateNestingFolder>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CreateNestingFolder";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FolderDetails();
  }
}

// @Route("/Nesting/Folder", "PUT")
export class RenameNestingFolder implements IReturn<FolderDetails> {
  public id: string;
  public folderName: string;

  public constructor(init?: Partial<RenameNestingFolder>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RenameNestingFolder";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FolderDetails();
  }
}

// @Route("/Nesting/Folder", "DELETE")
export class DeleteNestingFolder implements IReturn<SuccessCheck> {
  public folderId: string;
  public deleteNestsInFolder: boolean;

  public constructor(init?: Partial<DeleteNestingFolder>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteNestingFolder";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new SuccessCheck();
  }
}

// @Route("/SavedNestingSessionSearch", "GET")
export class SavedNestingSessionSearch
  extends NestSearchParams
  implements IReturn<SearchResults>
{
  public idempotencyToken: string;
  public folderId?: string;
  public searchType: FolderSearchType;

  public constructor(init?: Partial<SavedNestingSessionSearch>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SavedNestingSessionSearch";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new SearchResults();
  }
}

// @Route("/NestingProject/Restore", "POST")
export class RestoreExistingProject implements IReturn<RestoreResponse>, IPost {
  public file: string;
  public fileName: string;
  public projectName: string;
  public overwriteSettings: boolean;
  public keepOriginalName: boolean;

  public constructor(init?: Partial<RestoreExistingProject>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RestoreExistingProject";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new RestoreResponse();
  }
}

// @Route("/Login/Uris", "GET")
export class AuthenticationEndPoint
  implements IReturn<AuthenticationUris>, IGet
{
  public redirectUri: string;
  public clientId: string;

  public constructor(init?: Partial<AuthenticationEndPoint>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AuthenticationEndPoint";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new AuthenticationUris();
  }
}

// @Route("/interest", "POST")
export class InterestInProductShown implements IReturn<PathToSignUpUri> {
  public email: string;
  public apiKey: string;

  public constructor(init?: Partial<InterestInProductShown>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "InterestInProductShown";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PathToSignUpUri();
  }
}

// @Route("/callback", "POST")
export class CallbackRequest implements IReturn<CallbackResponse> {
  public email: string;
  public apiKey: string;
  public company: string;
  public telephone: string;
  public name: string;

  public constructor(init?: Partial<CallbackRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CallbackRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new CallbackResponse();
  }
}

// @Route("/contact", "POST")
export class SupportQuestionRequest implements IReturn<CallbackResponse> {
  public email: string;
  public apiKey: string;
  public company: string;
  public telephone: string;
  public name: string;
  public message: string;

  public constructor(init?: Partial<SupportQuestionRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SupportQuestionRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new CallbackResponse();
  }
}

// @Route("/version", "GET")
export class VersionInfo implements IReturn<VersionResponse> {
  public constructor(init?: Partial<VersionInfo>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "VersionInfo";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new VersionResponse();
  }
}

// @Route("/Alive", "GET")
export class AliveRequest implements IReturn<AliveResponse> {
  public constructor(init?: Partial<AliveRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AliveRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new AliveResponse();
  }
}

// @Route("/Payments/CCFreeTrial", "POST")
export class RequestCCFreeTrial implements IReturn<TrailRequestResponse> {
  // @Required()
  public companyName: string;

  // @Required()
  public website: string;

  // @Required()
  public telephone: string;

  // @Required()
  public businessEmail: string;

  public constructor(init?: Partial<RequestCCFreeTrial>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RequestCCFreeTrial";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new TrailRequestResponse();
  }
}

// @Route("/Payments/RestartSubscription", "POST")
export class RestartSubscription implements IReturn<PaymentModificationResult> {
  public subscriptionId: string;

  public constructor(init?: Partial<RestartSubscription>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RestartSubscription";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PaymentModificationResult();
  }
}

// @Route("/Payments/DayPass", "POST")
export class PurchaseDayPlan implements IReturn<PaymentModificationResult> {
  public subscriptionId: string;

  public constructor(init?: Partial<PurchaseDayPlan>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PurchaseDayPlan";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PaymentModificationResult();
  }
}

// @Route("/Payments/ChangePlan", "POST")
export class AlterSubscriptionPlan
  implements IReturn<PaymentModificationResult>
{
  public subscriptionId: string;
  public planId: string;

  public constructor(init?: Partial<AlterSubscriptionPlan>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AlterSubscriptionPlan";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PaymentModificationResult();
  }
}

// @Route("/Payments/Cancel", "POST")
export class CancelSubscription implements IReturn<PaymentModificationResult> {
  public subscriptionId: string;

  public constructor(init?: Partial<CancelSubscription>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CancelSubscription";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new PaymentModificationResult();
  }
}

// @Route("/AccountDetails", "GET")
export class GetAccountDetails implements IReturn<AccountDetails>, IGet {
  public constructor(init?: Partial<GetAccountDetails>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetAccountDetails";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new AccountDetails();
  }
}

// @Route("/Payments/Receipts", "GET")
export class ReceiptRequest implements IReturn<ReceiptResponse>, IGet {
  public skip: number;
  public take: number;

  public constructor(init?: Partial<ReceiptRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ReceiptRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new ReceiptResponse();
  }
}

// @Route("/checkout/step", "PUT")
export class CheckoutStep implements IPut {
  public reference: string;

  public constructor(init?: Partial<CheckoutStep>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CheckoutStep";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Payments/GetAvailableDiscount", "GET")
export class GetAvailableDiscount implements IReturn<DiscountResponse>, IGet {
  public constructor(init?: Partial<GetAvailableDiscount>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetAvailableDiscount";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new DiscountResponse();
  }
}

// @Route("/Payments/OverrideCheckout", "GET")
export class GetOverriddenPlanCheckout
  implements IReturn<CustomCheckout>, IGet
{
  public planId: string;
  public countryCode: string;

  public constructor(init?: Partial<GetOverriddenPlanCheckout>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetOverriddenPlanCheckout";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new CustomCheckout();
  }
}

// @Route("/Payments/Plans", "GET")
export class GetAvailablePlans implements IReturn<AvailablePlansData>, IGet {
  public constructor(init?: Partial<GetAvailablePlans>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetAvailablePlans";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new AvailablePlansData();
  }
}

// @Route("/Payments/Passthrough", "GET")
export class GetPaymentPassThroughData implements IReturn<PassThroughData> {
  public constructor(init?: Partial<GetPaymentPassThroughData>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetPaymentPassThroughData";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new PassThroughData();
  }
}

// @Route("/webhook/status", "GET")
export class WebhookStatus implements IReturn<string>, IGet {
  public constructor(init?: Partial<WebhookStatus>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "WebhookStatus";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return "";
  }
}

export class ProcessWebHooks {
  public content: string;

  public constructor(init?: Partial<ProcessWebHooks>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ProcessWebHooks";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/terms/", "GET")
export class FetchTermsAndConditions
  implements IReturn<TermsAndConditionsContent>, IGet
{
  public termsId?: number;

  public constructor(init?: Partial<FetchTermsAndConditions>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchTermsAndConditions";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new TermsAndConditionsContent();
  }
}

// @Route("/terms/", "POST")
export class AcceptTerms implements IReturn<TermsAccepted>, IPost {
  public id: number;
  public acceptedTime: string;

  public constructor(init?: Partial<AcceptTerms>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AcceptTerms";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new TermsAccepted();
  }
}

// @Route("/user/terms/", "GET")
export class FetchUsersTermsStatus implements IReturn<UserTermsStatus>, IGet {
  public constructor(init?: Partial<FetchUsersTermsStatus>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchUsersTermsStatus";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserTermsStatus();
  }
}

// @Route("/terms/current", "GET")
export class PublicTermsAndConditions
  implements IReturn<TermsAndConditionsContent>, IGet
{
  public constructor(init?: Partial<PublicTermsAndConditions>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PublicTermsAndConditions";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new TermsAndConditionsContent();
  }
}

// @Route("/terms/latest", "GET")
export class FetchLatestTermsAndConditions
  implements IReturn<TermsAndConditionsContent>, IGet
{
  public constructor(init?: Partial<FetchLatestTermsAndConditions>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchLatestTermsAndConditions";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new TermsAndConditionsContent();
  }
}

// @Route("/Feedback", "POST")
export class FeedbackSubmission implements IReturn<FeedbackResponse> {
  public feedbackType: GoodOrBadFeedback;
  public currentUri: string;
  public feedback: string;
  public screenShot: string;
  public sendToSupport: boolean;
  public projectId?: string;

  public constructor(init?: Partial<FeedbackSubmission>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FeedbackSubmission";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FeedbackResponse();
  }
}

// @Route("/Admin/ImportSessions", "GET")
export class GetImportSessionsForDate
  implements IReturn<HistoricImportSession[]>, IGet
{
  public startDate: string;
  public endDate: string;
  public userId?: string;
  public teamId?: string;

  public constructor(init?: Partial<GetImportSessionsForDate>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetImportSessionsForDate";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<HistoricImportSession>();
  }
}

// @Route("/Admin/MarkUserForDeletion", "PUT")
export class MarkUserForDeletion {
  public deleteAfter?: string;
  public userId: string;

  public constructor(init?: Partial<MarkUserForDeletion>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MarkUserForDeletion";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Admin/SetMaxUniqueNestsForReports", "PUT")
export class SetMaxUniqueNestsForReports {
  public maxUniqueNests: number;
  public userId: string;

  public constructor(init?: Partial<SetMaxUniqueNestsForReports>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetMaxUniqueNestsForReports";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Admin/ClearAwaitingWebhookFlag", "PUT")
export class ClearAwaitingWebhookFlag {
  public teamId: string;

  public constructor(init?: Partial<ClearAwaitingWebhookFlag>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ClearAwaitingWebhookFlag";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Admin/SetMaxImportLineCountPerPart", "PUT")
export class SetMaxImportLineCountPerPart {
  public maxImportLineCount: number;
  public userId: string;

  public constructor(init?: Partial<SetMaxImportLineCountPerPart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetMaxImportLineCountPerPart";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Admin/SetPlanDetails", "PUT")
export class SetPlanAllowance implements IReturn<PaddleMetaData> {
  public planId: string;
  public minutesAllowance: number;
  public isDayPass: boolean;

  public constructor(init?: Partial<SetPlanAllowance>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetPlanAllowance";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new PaddleMetaData();
  }
}

// @Route("/Admin/GetPlanDetails", "GET")
export class GetPlanAllowances implements IReturn<FetchedAllowances> {
  public constructor(init?: Partial<GetPlanAllowances>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetPlanAllowances";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new FetchedAllowances();
  }
}

// @Route("/Admin/AzureLinks/", "GET")
export class AzureLinksRequest implements IReturn<AzureLinks> {
  public constructor(init?: Partial<AzureLinksRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AzureLinksRequest";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new AzureLinks();
  }
}

// @Route("/Admin/Charts/NestCount", "GET")
export class NestCountChart implements IReturn<NestCountChartData> {
  public range: TimeRange;
  public startDate?: string;

  public constructor(init?: Partial<NestCountChart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestCountChart";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestCountChartData();
  }
}

// @Route("/Admin/Charts/Ranged/NestCount", "GET")
export class NestCountChartRanged
  extends ChartRangeData
  implements IReturn<NestCountChartData>
{
  public constructor(init?: Partial<NestCountChartRanged>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestCountChartRanged";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestCountChartData();
  }
}

// @Route("/Admin/Charts/NestingTraffic", "GET")
export class NestingTrafficChart implements IReturn<QueueLengthChartData> {
  public range: TimeRange;
  public startDate?: string;

  public constructor(init?: Partial<NestingTrafficChart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingTrafficChart";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new QueueLengthChartData();
  }
}

// @Route("/Admin/Charts/Range/NestingTraffic", "GET")
export class NestingTrafficChartRange
  extends ChartRangeData
  implements IReturn<QueueLengthChartData>
{
  public constructor(init?: Partial<NestingTrafficChartRange>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingTrafficChartRange";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new QueueLengthChartData();
  }
}

// @Route("/Admin/Charts/Range/ActiveUsers", "GET")
export class GetActiveUserChartRanged
  extends ChartRangeData
  implements IReturn<LoggedInUserCountsChart>
{
  public constructor(init?: Partial<GetActiveUserChartRanged>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetActiveUserChartRanged";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new LoggedInUserCountsChart();
  }
}

// @Route("/Admin/Charts/ActiveUsers", "GET")
export class GetActiveUserChart implements IReturn<LoggedInUserCountsChart> {
  public startTime?: string;
  public range: TimeRange;

  public constructor(init?: Partial<GetActiveUserChart>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetActiveUserChart";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new LoggedInUserCountsChart();
  }
}

// @Route("/Admin/Maintenance/", "PUT")
export class ChangeMaintenance implements IReturn<FeatureSetting> {
  public setValueTo: boolean;

  public constructor(init?: Partial<ChangeMaintenance>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ChangeMaintenance";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new FeatureSetting();
  }
}

// @Route("/Admin/Maintenance/", "GET")
export class GetMaintenance implements IReturn<FeatureSetting> {
  public constructor(init?: Partial<GetMaintenance>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetMaintenance";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new FeatureSetting();
  }
}

// @Route("/Admin/Maintenance/Log", "GET")
export class GetMaintenanceLog implements IReturn<MaintenanceLog> {
  public constructor(init?: Partial<GetMaintenanceLog>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetMaintenanceLog";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new MaintenanceLog();
  }
}

// @Route("/Admin/Emails/Log", "GET")
export class GetEmailLog implements IReturn<EmailLog> {
  public constructor(init?: Partial<GetEmailLog>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetEmailLog";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new EmailLog();
  }
}

// @Route("/Admin/EmailMaintenanceMessage/preview", "POST")
export class PreviewEmailMaintenanceMessage
  implements IReturn<EmailMaintenancePreview>
{
  public message: string;
  public subject: string;

  public constructor(init?: Partial<PreviewEmailMaintenanceMessage>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PreviewEmailMaintenanceMessage";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new EmailMaintenancePreview();
  }
}

// @Route("/Admin/EmailMaintenanceMessage", "POST")
export class EmailMaintenanceMessage
  implements IReturn<EmailMaintenanceResponse>
{
  public message: string;
  public subject: string;

  public constructor(init?: Partial<EmailMaintenanceMessage>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "EmailMaintenanceMessage";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new EmailMaintenanceResponse();
  }
}

// @Route("/Admin/CustomMaintenanceMessage", "POST")
export class SetMaintenanceMessage {
  public message: string;
  public expires: string;

  public constructor(init?: Partial<SetMaintenanceMessage>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetMaintenanceMessage";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/Admin/CustomMaintenanceMessage", "DELETE")
export class ClearMaintenanceMessage {
  public constructor(init?: Partial<ClearMaintenanceMessage>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ClearMaintenanceMessage";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {}
}

export class NestingReport {
  public from: string;
  public to: string;
  public userId?: string;

  public constructor(init?: Partial<NestingReport>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingReport";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {}
}

// @Route("/Admin/Reports/Nesting/CSV", "GET")
export class NestingReportCSV
  extends NestingReport
  implements IReturn<Blob>, IGet
{
  public constructor(init?: Partial<NestingReportCSV>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingReportCSV";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/Admin/Reports/Nesting/JSON", "GET")
export class NestingReportJSON
  extends NestingReport
  implements IReturn<NestingReportResponse>, IGet
{
  public constructor(init?: Partial<NestingReportJSON>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingReportJSON";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new NestingReportResponse();
  }
}

// @Route("/Admin/Failures/Download", "GET")
export class NestingFailureFile implements IReturn<Blob>, IGet {
  public id: string;

  public constructor(init?: Partial<NestingFailureFile>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestingFailureFile";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/Admin/Nesting/Overview", "GET")
export class GetNestingOverview implements IReturn<OverviewStatistics> {
  public overviewPeriod: number;
  public refreshPeriod: number;
  public startDate?: string;

  public constructor(init?: Partial<GetNestingOverview>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetNestingOverview";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new OverviewStatistics();
  }
}

// @Route("/Admin/FetchUsers", "GET")
export class FetchAllUsers implements IReturn<UserBasics[]>, IGet {
  public ignoreLocked: boolean;
  public showDeleted: boolean;

  public constructor(init?: Partial<FetchAllUsers>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FetchAllUsers";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<UserBasics>();
  }
}

// @Route("/Admin/Lock/Email", "POST")
export class LockUserByEmail implements IReturn<LockedSuccessStatus> {
  public email: string;
  public setLockValueTo: boolean;

  public constructor(init?: Partial<LockUserByEmail>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "LockUserByEmail";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new LockedSuccessStatus();
  }
}

// @Route("/Admin/FreeAccount/Email", "POST")
export class FreeAccountByEmail implements IReturn<FreeAccountSuccessStatus> {
  public email: string;
  public setValueTo: boolean;
  public freeUntil: string;

  public constructor(init?: Partial<FreeAccountByEmail>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FreeAccountByEmail";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FreeAccountSuccessStatus();
  }
}

// @Route("/Admin/FreeAccount/Id", "POST")
export class FreeAccountById implements IReturn<FreeAccountSuccessStatus> {
  public userId: string;
  public setValueTo: boolean;
  public freeUntil: string;

  public constructor(init?: Partial<FreeAccountById>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "FreeAccountById";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new FreeAccountSuccessStatus();
  }
}

// @Route("/Admin/Lock/Id", "POST")
export class LockUserById implements IReturn<LockedSuccessStatus> {
  public userId: string;
  public setLockValueTo: boolean;

  public constructor(init?: Partial<LockUserById>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "LockUserById";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new LockedSuccessStatus();
  }
}

// @Route("/Admin/Failures", "GET")
export class UserFailures implements IReturn<UserFailureData> {
  public userId?: string;
  public id?: string;
  public limitToTypeId: string;
  public page: number;
  public limit?: number;

  public constructor(init?: Partial<UserFailures>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UserFailures";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserFailureData();
  }
}

// @Route("/Admin/GDPR", "POST")
export class GDPRRequest implements IReturn<GDPRAdminComplete> {
  public userId: string;

  public constructor(init?: Partial<GDPRRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GDPRRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new GDPRAdminComplete();
  }
}

// @Route("/Admin/ExportAllUsers", "POST")
export class ExportAllUsers implements IReturn<Blob>, IPost {
  public ignoreLocked: boolean;

  public constructor(init?: Partial<ExportAllUsers>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ExportAllUsers";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/admin/team/logs", "GET")
export class GetTeamLogs implements IReturn<ViewableTeamAction[]>, IGet {
  public teamId: string;
  public take: number;
  public skip: number;

  public constructor(init?: Partial<GetTeamLogs>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetTeamLogs";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<ViewableTeamAction>();
  }
}

// @Route("/admin/team/members", "GET")
export class GetTeamMembers {
  public teamId: string;

  public constructor(init?: Partial<GetTeamMembers>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetTeamMembers";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {}
}

// @Route("/Admin/Trials", "POST")
export class TrialAcceptOrDeny implements IPost {
  public teamId: string;
  public userId: string;
  public action: TrialAction;
  public ticket?: number;

  public constructor(init?: Partial<TrialAcceptOrDeny>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "TrialAcceptOrDeny";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/Admin/User/ExtendPlan/", "POST")
export class ExtentUsersPlan implements IReturn<ExtendPlanSuccessStatus> {
  public userId: string;
  public addMinutes: number;

  public constructor(init?: Partial<ExtentUsersPlan>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ExtentUsersPlan";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new ExtendPlanSuccessStatus();
  }
}

// @Route("/Admin/MaxUpload/Email", "POST")
export class MaxFileSizeByEmail implements IReturn<MaxFileSizeSuccessStatus> {
  public email: string;
  public setValueTo: number;

  public constructor(init?: Partial<MaxFileSizeByEmail>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MaxFileSizeByEmail";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new MaxFileSizeSuccessStatus();
  }
}

// @Route("/Admin/MaxUpload/Id", "POST")
export class MaxFileSizeById implements IReturn<MaxFileSizeSuccessStatus> {
  public userId: string;
  public setValueTo: number;

  public constructor(init?: Partial<MaxFileSizeById>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "MaxFileSizeById";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new MaxFileSizeSuccessStatus();
  }
}

// @Route("/Admin/User", "GET")
export class GetUserProfileByEmailOrUserId implements IReturn<UserProfileData> {
  public email: string;
  public userId?: string;

  public constructor(init?: Partial<GetUserProfileByEmailOrUserId>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetUserProfileByEmailOrUserId";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new UserProfileData();
  }
}

// @Route("/Admin/DeleteAfter", "POST")
export class DeleteAfterRequest implements IReturn<User> {
  public userId: string;
  public deleteAfter: string;

  public constructor(init?: Partial<DeleteAfterRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeleteAfterRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new User();
  }
}

// @Route("/Admin/NestingSeconds", "PUT")
export class SetNestingSeconds implements IReturn<GlobalSettings> {
  public seconds: number;
  public userId: string;

  public constructor(init?: Partial<SetNestingSeconds>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetNestingSeconds";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new GlobalSettings();
  }
}

// @Route("/Admin/ExportLimit", "PUT")
export class SetExportLimit {
  public limit: number;
  public userId: string;

  public constructor(init?: Partial<SetExportLimit>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetExportLimit";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {}
}

// @Route("/Admin/Team/Overview", "GET")
export class GetTeamUserDetails implements IReturn<TeamOverview> {
  public teamId: string;

  public constructor(init?: Partial<GetTeamUserDetails>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetTeamUserDetails";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new TeamOverview();
  }
}

// @Route("/Admin/Team/Disband", "PUT")
export class SetTeamDisbandmentDate implements IReturn<TeamOverview> {
  public teamId: string;
  public disbandAfter: string;

  public constructor(init?: Partial<SetTeamDisbandmentDate>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SetTeamDisbandmentDate";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new TeamOverview();
  }
}

// @Route("/Admin/Teams", "GET")
export class GetTeamList implements IReturn<TeamOverview[]> {
  public constructor(init?: Partial<GetTeamList>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetTeamList";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<TeamOverview>();
  }
}

// @Route("/Admin/Teams/Allowance", "PUT")
export class UpdateTeamAllowance implements IReturn<TeamOverview> {
  public teamId: string;
  public newAllowance: number;

  public constructor(init?: Partial<UpdateTeamAllowance>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "UpdateTeamAllowance";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new TeamOverview();
  }
}

// @Route("/GDPR", "GET")
export class GdprCompleteProcess implements IReturn<GDPRComplete> {
  public token: string;

  public constructor(init?: Partial<GdprCompleteProcess>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GdprCompleteProcess";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new GDPRComplete();
  }
}

// @Route("/NestingProject/Renders/Part/PNG", "GET")
export class PartPNGRender
  extends BasePartRenderer
  implements IReturn<Blob>, INestingRenderRequest, IPartRenderRequest
{
  public constructor(init?: Partial<PartPNGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PartPNGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/NestingProject/Renders/Part/SVG", "GET")
export class PartSVGRender
  extends BasePartRenderer
  implements IReturn<Blob>, INestingRenderRequest, IPartRenderRequest
{
  public constructor(init?: Partial<PartSVGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PartSVGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/NestingProject/Renders/Sheet/PNG", "GET")
export class SheetPNGRender
  extends BaseSheetRenderer
  implements IReturn<Blob>, INestingRenderRequest
{
  public constructor(init?: Partial<SheetPNGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SheetPNGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/NestingProject/Renders/Sheet/SVG", "GET")
export class SheetSVGRender
  extends BaseSheetRenderer
  implements IReturn<Blob>, INestingRenderRequest
{
  public constructor(init?: Partial<SheetSVGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "SheetSVGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/NestingProject/Renders/Result/PNG", "GET")
export class NestResultPNGRender
  extends BaseNestRenderer
  implements IReturn<Blob>, INestingRenderRequest
{
  public constructor(init?: Partial<NestResultPNGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestResultPNGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/NestingProject/Renders/Result/SVG", "GET")
export class NestResultSVGRender
  extends BaseNestRenderer
  implements IReturn<Blob>, INestingRenderRequest
{
  public constructor(init?: Partial<NestResultSVGRender>) {
    super(init);
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "NestResultSVGRender";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

// @Route("/csvimport/headers", "POST")
export class CSVGetHeaders implements IReturn<CSVHeaderResponse>, IPost {
  public file: string;

  public constructor(init?: Partial<CSVGetHeaders>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CSVGetHeaders";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new CSVHeaderResponse();
  }
}

// @Route("/csvimport/parts", "POST")
export class PartsFromCSV implements IReturn<CSVPartResponse>, IPost {
  public file: string;
  public assignments: CSVHeaderAssignments;

  public constructor(init?: Partial<PartsFromCSV>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "PartsFromCSV";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new CSVPartResponse();
  }
}

// @Route("/Team/Rename", "PUT")
export class RenameTeam implements IReturn<SuccessResponse> {
  public name: string;

  public constructor(init?: Partial<RenameTeam>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RenameTeam";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new SuccessResponse();
  }
}

// @Route("/Team/Subscription/", "DELETE")
export class CancelTeamSubscription implements IReturn<TeamMembersState> {
  public constructor(init?: Partial<CancelTeamSubscription>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "CancelTeamSubscription";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Team/Invite/Revoke", "DELETE")
export class RevokeInvite implements IReturn<TeamMembersState> {
  public inviteId: string;

  public constructor(init?: Partial<RevokeInvite>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RevokeInvite";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Team/Invites", "GET")
export class GetPendingInvite implements IReturn<PublicInvite> {
  public constructor(init?: Partial<GetPendingInvite>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetPendingInvite";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new PublicInvite();
  }
}

// @Route("/Team/Invite", "POST")
export class InviteToTeam implements IReturn<TeamMembersState> {
  public receiverEmail: string;

  public constructor(init?: Partial<InviteToTeam>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "InviteToTeam";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Invites", "GET")
export class GetUserInvites implements IReturn<UserTeamInvitations[]>, IGet {
  public constructor(init?: Partial<GetUserInvites>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetUserInvites";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Array<UserTeamInvitations>();
  }
}

// @Route("/Team/Invite/Decline", "DELETE")
export class DeclineInvite implements IReturn<DeclinedStatus>, IDelete {
  public inviteId: string;

  public constructor(init?: Partial<DeclineInvite>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "DeclineInvite";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new DeclinedStatus();
  }
}

// @Route("/Team/Invite/Accept", "PUT")
export class AcceptInvite implements IReturn<AcceptStatus>, IPut {
  public inviteId: string;

  public constructor(init?: Partial<AcceptInvite>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "AcceptInvite";
  }
  public getMethod() {
    return "PUT";
  }
  public createResponse() {
    return new AcceptStatus();
  }
}

// @Route("/Team/User", "DELETE")
export class RemoveUserFromTeam implements IReturn<TeamMembersState>, IDelete {
  public userId: string;

  public constructor(init?: Partial<RemoveUserFromTeam>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RemoveUserFromTeam";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Team/User/Invite", "DELETE")
export class RemoveUserFromTeamByInvite implements IReturn<SuccessResponse> {
  public inviteId: string;

  public constructor(init?: Partial<RemoveUserFromTeamByInvite>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "RemoveUserFromTeamByInvite";
  }
  public getMethod() {
    return "DELETE";
  }
  public createResponse() {
    return new SuccessResponse();
  }
}

// @Route("/Team/Details", "GET")
export class GetTeamStatus implements IReturn<TeamMembersState> {
  public constructor(init?: Partial<GetTeamStatus>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetTeamStatus";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Team/Leave", "POST")
export class LeaveTeam implements IReturn<TeamMembersState> {
  public constructor(init?: Partial<LeaveTeam>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "LeaveTeam";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new TeamMembersState();
  }
}

// @Route("/Export", "POST")
export class ExportRequest {
  public type: ExportType;
  public nestingSessionId: string;
  public resultId: string;
  public exportName: string;
  public version: string;

  public constructor(init?: Partial<ExportRequest>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "ExportRequest";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {}
}

// @Route("/Export", "GET")
export class GetCompletedExport
  implements IReturn<Blob>, IHasLinkedFileDownload
{
  public reportId: string;

  public constructor(init?: Partial<GetCompletedExport>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "GetCompletedExport";
  }
  public getMethod() {
    return "GET";
  }
  public createResponse() {
    return new Blob();
  }
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
  /** @description AuthProvider, e.g. credentials */
  // @DataMember(Order=1)
  public provider: string;

  // @DataMember(Order=2)
  public state: string;

  // @DataMember(Order=3)
  public oauth_token: string;

  // @DataMember(Order=4)
  public oauth_verifier: string;

  // @DataMember(Order=5)
  public userName: string;

  // @DataMember(Order=6)
  public password: string;

  // @DataMember(Order=7)
  public rememberMe?: boolean;

  // @DataMember(Order=9)
  public errorView: string;

  // @DataMember(Order=10)
  public nonce: string;

  // @DataMember(Order=11)
  public uri: string;

  // @DataMember(Order=12)
  public response: string;

  // @DataMember(Order=13)
  public qop: string;

  // @DataMember(Order=14)
  public nc: string;

  // @DataMember(Order=15)
  public cnonce: string;

  // @DataMember(Order=17)
  public accessToken: string;

  // @DataMember(Order=18)
  public accessTokenSecret: string;

  // @DataMember(Order=19)
  public scope: string;

  // @DataMember(Order=20)
  public returnUrl: string;

  // @DataMember(Order=21)
  public meta: { [index: string]: string };

  public constructor(init?: Partial<Authenticate>) {
    (Object as any).assign(this, init);
  }
  public getTypeName() {
    return "Authenticate";
  }
  public getMethod() {
    return "POST";
  }
  public createResponse() {
    return new AuthenticateResponse();
  }
}
