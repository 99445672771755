import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import ConfirmModal from "./features/common/ConfirmModal";
import DashBoard from "./pages/DashBoard";
import Failures from "./pages/Failures";
import ImportReportScreen from "./features/importReports/ImportReportsScreen";
import Logout from "./pages/Logout";
import MaintenanceModal from "./features/maintenance/MaintenanceModal";
import MaintenanceScreen from "./features/maintenance/MaintenanceScreen";
import NestingReportsScreen from "./features/nestingreports/NestingReportsScreen";
import PageTitle from "./components/PageTitle";
import Sidebar from "./components/Sidebar";
import TeamAccount from "./pages/TeamAccount";
import Teams from "./pages/Teams";
import ToastMessagesScreen from "./features/toastMessages/ToastMessagesScreen";
import Trial from "./pages/Trial";
import TypeBackground from "./components/TypeBackground";
import UserAccount from "./pages/UserAccount";
import Users from "./pages/Users";
import { getAzureLinks } from "./store/azureLinksSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAzureLinks());
  }, [dispatch]);

  return (
    <Router>
      <div className="theme-container h-100 d-flex flex-column">
        <ToastMessagesScreen />
        {/*  */}
        <Sidebar />
        {/*  */}
        <TypeBackground>
          <main className="theme-main-container flex-grow-1">
            <Switch>
              <PageTitle>
                {/* Users */}
                <Route exact path="/users">
                  <Users />
                </Route>
                {/* Teams */}
                <Route exact path="/teams">
                  <Teams />
                </Route>
                {/* User Account */}
                <Route exact path="/user-account">
                  <UserAccount />
                </Route>
                {/* Team Account */}
                <Route exact path="/team-account">
                  <TeamAccount />
                </Route>
                {/* Dashboard */}
                <Route exact path="/dashboard">
                  <DashBoard />
                </Route>
                {/* Failures */}
                <Route exact path="/failures">
                  <Failures />
                </Route>
                {/* Nesting Reports */}
                <Route exact path="/nesting-reports">
                  <NestingReportsScreen />
                </Route>
                {/* Import Reports */}
                <Route exact path="/import-reports">
                  <ImportReportScreen />
                </Route>
                {/* Maintenance */}
                <Route exact path="/maintenance">
                  <MaintenanceScreen />
                </Route>
                {/* Home  */}
                <Route
                  exact
                  path={"/"}
                  render={() => <Redirect to="/dashboard" />}
                />
                {/* logout */}
                <Route exact path="/logout">
                  <Logout />
                </Route>
                {/* Trial */}
                <Route exact path="/trial">
                  <Trial />
                </Route>
              </PageTitle>
            </Switch>
          </main>
        </TypeBackground>
        {/* Modal */}
        <MaintenanceModal />
        <ConfirmModal />
      </div>
    </Router>
  );
}

export default App;
