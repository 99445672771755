import { FunctionComponent, memo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HistoricImportSession } from "../../service/api.dtos";
import { dateFormatter } from "../../components/DateFormatter";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

const ImportReportsBody: FunctionComponent<{
  importSessions: HistoricImportSession[];
}> = memo(({ importSessions }) => {
  const history = useHistory();
  function accountClick(id: string) {
    return history.push({
      pathname: "/user-account",
      search: `?userId=${id}`,
    });
  }

  return (
    <tbody className="small">
      {importSessions.map((session) => {
        return (
          <tr key={session.sessionId} className="theme-hover-row">
            <th scope="row">{session?.firstName}</th>
            <th scope="row">{session?.surname}</th>
            <td className="text-nowrap">
              {dateFormatter(session?.createdDate)}
            </td>
            <td className="text-nowrap">
              {dateFormatter(session?.completeDate)}
            </td>
            <td className="text-nowrap">
              {dateFormatter(session?.cancelledDate)}
            </td>

            <td className="text-center">
              {session?.finalPartCount ?? (
                <div className="opacity-50 text-center">--</div>
              )}
            </td>
            <td className="text-center">
              {session?.finalFileCount ?? (
                <div className="opacity-50 text-center">--</div>
              )}
            </td>
            {/* Account button */}
            <td className="align-middle text-end">
              <button
                className="btn btn-sm btn-secondary float-end text-body py-0 border align-middle"
                onClick={() => accountClick(session.userId)}
              >
                <FontAwesomeIcon icon={faUser} fixedWidth />
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
});

export default ImportReportsBody;
