import React, { FunctionComponent, useEffect, useState } from "react";
import { disposeUserState, getUser } from "../store/searchUserSlice";
import {
  faCircleNotch,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  getUserProfileData,
  getUserProfileDataLoading,
} from "../store/searchUser";
import { useDispatch, useSelector } from "react-redux";

import DisplayCard from "../components/DisplayCard";
import FailedNestsList from "../components/FailedNestsList";
import FailuresList from "../components/FailuresList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetUserProfileByEmailOrUserId } from "../service/api.dtos";
import LoadingOverlay from "../components/LoadingOverlay";
import PlanData from "../features/usersAcount/PlanData";
import QuestionnaireData from "../features/usersAcount/QuestionnaireData";
import TeamData from "../features/usersAcount/TeamData";
import UserInfo from "../features/usersAcount/UserInfo";
import { disposeUsersFailureState } from "../store/usersFailuresSlice";
import { getNestFailuresLoading } from "../store/nestFailures";
import { useHistory } from "react-router-dom";
import { useQuery } from "../utils";

const UserAccount: FunctionComponent = () => {
  enum SearchType {
    email = 1,
    userId = 2,
  }

  const userProfileData = useSelector(getUserProfileData);
  const loading = useSelector(getUserProfileDataLoading);
  const failureListLoading = useSelector(getNestFailuresLoading);

  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  //maybe add  state args here on load - searchQueryArg
  const [searchInput, setSearchInput] = useState<string>(
    query.get("email") ??
      query.get("userId") ??
      userProfileData.user?.email ??
      ""
  );

  useEffect(() => {
    if (userProfileData.user?.displayName) {
      document.title += " " + userProfileData.user?.displayName;
    }
    return () => {
      document.title = document.title.replace(
        userProfileData.user?.displayName,
        ""
      );
    };
  }, [userProfileData.user?.displayName]);

  const [searchTypeState, setSearchTypeState] = useState<SearchType>(
    query.get("email")
      ? SearchType.email
      : query.get("userId")
      ? SearchType.userId
      : SearchType.email
  );

  function clear() {
    setSearchInput("");
    setSearchTypeState(SearchType.email);
    dispatch(disposeUserState());
    dispatch(disposeUsersFailureState());
  }

  useEffect(() => {
    let stringBuilder = searchInput
      ? `?${SearchType[searchTypeState]}=${encodeURIComponent(searchInput)} `
      : "";

    return history.push({
      pathname: "/user-account",
      search: stringBuilder,
    });
  }, [history, searchInput, searchTypeState]); // eslint-disable-line react-hooks/exhaustive-deps

  //onload
  useEffect(() => {
    if (searchInput) {
      handleSubmit();
    }

    // Remove Dash lane elements
    setTimeout(() => {
      const test = document.querySelectorAll("[data-dashlanecreated]");

      test.forEach((e) => e.remove());
    }, 400);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function inputHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;

    setSearchInput(value);
  }

  function handleKeypress(e: any): void {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    e && e.preventDefault();
    const trimSearch = searchInput.trim();
    setSearchInput(trimSearch);

    dispatch(disposeUsersFailureState());

    //is value email - basic
    const isEmail = /@/.test(trimSearch);

    //If there's email in search box and user id is selected
    let searchType: SearchType;
    if (isEmail) {
      searchType = SearchType.email;
    } else {
      searchType = SearchType.userId;
    }

    setSearchTypeState(searchType);

    dispatch(
      getUser({
        ...{},
        [SearchType[searchType]]: trimSearch,
      } as GetUserProfileByEmailOrUserId)
    );
  }

  function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;
    setSearchInput("");
    setSearchTypeState(parseInt(value, 10));
  }

  return (
    <section className="container-lg py-3 h-100">
      <div className="card text-body bg-dark h-100">
        <div className="p-3 d-flex align-items-center">
          <h4 className="text-nowrap text-capitalize">
            {userProfileData.user
              ? userProfileData.user.displayName
              : "Account"}
          </h4>
          {/* Bar spacer */}
          <div className="rounded flex-fill mx-3 bg-secondary w-100 h-50 text-secondary">
            .
          </div>
          {/* Date Filter */}
          <form
            className="input-group input-group-sm"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {/* search type */}

            <select
              className="form-select theme-failure-bar-id-types"
              onChange={handleSelect}
              name="searchType"
              value={searchTypeState}
            >
              <option value="1">Email</option>
              <option value="2">User Id</option>
            </select>

            {/* Search  */}
            <input
              type="text"
              name="localSearch"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              value={searchInput}
              onChange={inputHandler}
              onKeyUp={handleKeypress}
            />

            {/* Clear */}
            <button
              className="btn btn-secondary border bg-dark"
              type="button"
              onClick={clear}
            >
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </button>
            {/* Search no button */}
            <button
              className="btn btn-secondary border bg-dark"
              id="button-accountSearch"
              type="submit"
            >
              {loading === "pending" ? (
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  fixedWidth
                  className="text-primary"
                />
              ) : (
                <FontAwesomeIcon icon={faSearch} fixedWidth />
              )}
            </button>
          </form>
        </div>
        <div className="h-100 d-flex position-relative mx-3 mb-3">
          <div className="border rounded d-flex flex-column theme-overflow">
            {/* Loading */}
            {searchInput !== userProfileData.user?.email &&
            searchInput !== userProfileData.user?.id &&
            (loading === "pending" || failureListLoading === "pending") ? (
              <LoadingOverlay loading={loading} />
            ) : (
              <>
                {userProfileData.user ? (
                  <>
                    {/* // User Account info */}
                    <div className="p-3 small">
                      <div className="row mb-2">
                        <div className="col-12 col-xxl-6 d-flex flex-column">
                          <UserInfo />
                        </div>

                        <div className="col-12 col-xxl-6 d-flex flex-column">
                          <PlanData />
                          <TeamData />
                          <QuestionnaireData />
                        </div>
                      </div>

                      {/* Stat Cards */}
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <legend>Current Plan Nest Stats</legend>
                          <div className="row g-3">
                            {/* Failed Nests */}
                            <div className="col-12 col-md-4 d-flex">
                              <DisplayCard title="Failed Nests">
                                {userProfileData.planData.currentPlanNestStats.failedNests.toLocaleString()}
                              </DisplayCard>
                            </div>
                            {/* Failed Percentage */}
                            <div className="col-12 col-md-4 d-flex">
                              <DisplayCard title="Failed Percentage">
                                <>
                                  {Math.ceil(
                                    userProfileData.planData
                                      .currentPlanNestStats.failurePercentage
                                  ).toString()}
                                  <small>%</small>
                                </>
                              </DisplayCard>
                            </div>
                            {/* Total Nests */}
                            <div className="col-12 col-md-4 d-flex">
                              <DisplayCard title="Total Nests">
                                {userProfileData.planData.currentPlanNestStats.totalNests.toLocaleString()}
                              </DisplayCard>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                        <div className="col-12 col-md-6">
                          <legend>All Time Nest Stats</legend>
                          <div className="row g-3">
                            {/* Failed Nests */}
                            <div className="col-12 col-md-4 d-flex">
                              <DisplayCard title="Failed Nests">
                                {userProfileData.allTimeNestStats.failedNests.toLocaleString()}
                              </DisplayCard>
                            </div>
                            {/* Failed Percentage */}
                            <div className="col-12 col-md-4  d-flex">
                              <DisplayCard title="Failed Percentage">
                                <>
                                  {Math.ceil(
                                    userProfileData.allTimeNestStats
                                      .failurePercentage
                                  ).toString()}
                                  <small>%</small>
                                </>
                              </DisplayCard>
                            </div>
                            {/* Total Nests */}
                            <div className="col-12 col-md-4  d-flex">
                              <DisplayCard title="Total Nests">
                                {userProfileData.allTimeNestStats.totalNests.toLocaleString()}
                              </DisplayCard>
                            </div>
                          </div>
                        </div>

                        {/*  */}
                      </div>
                    </div>

                    <div className="card-header border-top border-bottom">
                      <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                          <button
                            className="nav-link active"
                            id="failures-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#failures-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="failures-tab-pane"
                            aria-selected="true"
                          >
                            <span className="fw-bold text-white">Failures</span>
                          </button>
                        </li>
                        <li className="nav-item">
                          <button
                            className="nav-link   "
                            id="failed-nests-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#failed-nests-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="failed-nests-tab-pane"
                            aria-selected="false"
                          >
                            <span className="fw-bold text-white">
                              Failed Nests
                            </span>
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Tab Panes */}
                    <div className="tab-content" id="myTabContent">
                      {/* Failure Tab Pane List */}
                      <div
                        id="failures-tab-pane"
                        className="tab-pane fade show active"
                        tabIndex={0}
                      >
                        <FailuresList />
                      </div>

                      {/* Failed Nest Tab List */}
                      <div
                        id="failed-nests-tab-pane"
                        className="tab-pane fade  "
                        tabIndex={0}
                      >
                        <FailedNestsList />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="theme-nothing-found  d-flex flex-column flex-grow-1 justify-content-center align-self-center">
                    {loading === "failed" && "Nothing Found 😥"}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/*  */}
    </section>
  );
};
export default UserAccount;
