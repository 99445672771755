function formatAsPercentage(num: number | undefined): JSX.Element {
  return num ? (
    <div className="text-end">
      {new Intl.NumberFormat("default", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num)}
    </div>
  ) : (
    <div className="opacity-50 text-center">--</div>
  );
}

export default formatAsPercentage;
