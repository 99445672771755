import { Link, NavLink } from "react-router-dom";
import {
  faBug,
  faChartLine,
  faExternalLinkAlt,
  faFileCsv,
  faUser,
  faUserFriends,
  faUsers,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoType from "./LogoType";
import { getAzureLinksState } from "../store/azureLinks";
import { useAppSelector } from "../hooks/reduxTypedHooks";

export default function Sidebar() {
  const AzureLinksState = useAppSelector(getAzureLinksState);

  return (
    <aside className="theme-sidebar-container d-flex flex-column justify-content-between flex-shrink-0 py-4 px-3">
      <div>
        <Link to="/" className="text-decoration-none position-relative  ">
          <h4 className="theme-logo text-center">
            <span>
              Admin Portal
              <LogoType />
            </span>
            <br />
            <span className="theme-ef-logo">efficient software</span>
          </h4>
        </Link>
        <hr />
        <ul className="theme-nav nav nav-pills flex-column mb-auto fw-bold">
          <li className="nav-item mb-3">
            {/* Dashboard Link */}
            <NavLink className="nav-link text-body ps-4" to="/dashboard">
              <FontAwesomeIcon icon={faChartLine} fixedWidth className="me-2" />
              Dashboard
            </NavLink>
          </li>

          <li className="nav-item mb-3">
            {/* Users Link */}
            <NavLink className="nav-link text-body ps-4" to="/users">
              <FontAwesomeIcon icon={faUsers} fixedWidth className="me-2" />
              Users
            </NavLink>
          </li>

          {/* Teams Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/teams">
              <FontAwesomeIcon icon={faUsers} fixedWidth className="me-2" />
              Teams
            </NavLink>
          </li>

          {/* User Account Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/user-account">
              <FontAwesomeIcon icon={faUser} fixedWidth className="me-2" />
              User Account
            </NavLink>
          </li>

          {/* Team Account Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/team-account">
              <FontAwesomeIcon
                icon={faUserFriends}
                fixedWidth
                className="me-2"
              />
              Team Account
            </NavLink>
          </li>

          {/* Failures Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/failures">
              <FontAwesomeIcon icon={faBug} fixedWidth className="me-2" />
              Failures
            </NavLink>
          </li>

          {/* Nesting Reports Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/nesting-reports">
              <FontAwesomeIcon icon={faFileCsv} fixedWidth className="me-2" />
              Nesting Reports
            </NavLink>
          </li>

          {/* Import Reports Link */}
          <li className="nav-item mb-3">
            <NavLink className="nav-link text-body ps-4" to="/import-reports">
              <FontAwesomeIcon icon={faFileCsv} fixedWidth className="me-2" />
              Import Reports
            </NavLink>
          </li>

          {/* Maintenance */}
          <li className="nav-item">
            <NavLink className="nav-link text-body ps-4" to="/maintenance">
              <FontAwesomeIcon icon={faWrench} fixedWidth className="me-2" />
              Maintenance
            </NavLink>
          </li>
        </ul>
      </div>

      <div>
        {/* Links */}
        <ul className="list-unstyled mb-4">
          {AzureLinksState.azureLinks?.links &&
            Object.entries(AzureLinksState.azureLinks.links).map(
              (link, i, arr) => {
                return (
                  <li key={link[1]}>
                    {" "}
                    <a
                      href={link[1]}
                      target="_blank"
                      rel="noreferrer"
                      className="mx-4"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        className="me-2"
                        fixedWidth
                      />
                      {link[0]}
                    </a>{" "}
                  </li>
                );
              }
            )}
        </ul>
      </div>
    </aside>
  );
}
