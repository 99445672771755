export type SortDescriptor = {
  active: boolean;
  order: "asc" | "desc";
  sortable?: boolean;
  sortedIndexes: number[];
};

export type TableHeader = {
  property: string;
  sort: SortDescriptor;
  title: string;
  width?: number;
  cssClass?: string;
  toolTipText?: string;
  tableCellWidth?: string;
};

export const toggleHeaderSort = (
  targetHeader: TableHeader,
  headers: TableHeader[],
  toSort: any[],
  idProperty: string
): TableHeader[] => {
  const headerIndex = headers.findIndex(
    (sHeader) => sHeader.property === targetHeader.property
  );

  const active = !(
    targetHeader.sort.order === "desc" && targetHeader.sort.active
  );

  const order = active
    ? targetHeader.sort.order === "desc"
      ? "asc"
      : "desc"
    : targetHeader.sort.order;

  if (targetHeader.property.length <= 0 || toSort.length <= 0) {
    return headers.map((sHeader, index) => {
      if (index === headerIndex) {
        return {
          ...sHeader,
          sort: {
            active: false,
            order,
            sortable: sHeader.sort.sortable,
            sortedIndexes: [],
          },
        };
      } else {
        return {
          ...sHeader,
          sort: {
            ...sHeader.sort,
            active: false,
          },
        };
      }
    });
  }

  const sortMethod = (a: any, b: any) => {
    let aProp = a[targetHeader.property];
    let bProp = b[targetHeader.property];

    // Handle undefined values first
    if (aProp === undefined) return 1;
    if (bProp === undefined) return -1;

    // Handle numbers
    if (typeof aProp === "number" && typeof bProp === "number") {
      return order === "desc" ? bProp - aProp : aProp - bProp;
    }

    // Handle strings
    if (typeof aProp === "string" && typeof bProp === "string") {
      return order === "desc"
        ? bProp.localeCompare(aProp)
        : aProp.localeCompare(bProp);
    }

    // Handle booleans
    if (typeof aProp === "boolean" && typeof bProp === "boolean") {
      return order === "desc"
        ? aProp === bProp
          ? 0
          : aProp
          ? -1
          : 1
        : aProp === bProp
        ? 0
        : aProp
        ? 1
        : -1;
    }

    // Handle dates
    if (aProp instanceof Date && bProp instanceof Date) {
      return order === "desc"
        ? bProp.getTime() - aProp.getTime()
        : aProp.getTime() - bProp.getTime();
    }

    // Handle objects
    if (typeof aProp === "object" && typeof bProp === "object") {
      return order === "desc"
        ? JSON.stringify(bProp).localeCompare(JSON.stringify(aProp))
        : JSON.stringify(aProp).localeCompare(JSON.stringify(bProp));
    }

    // Default case
    return 0;
  };

  const sortedItems = active ? toSort.slice().sort(sortMethod) : [];

  const itemIndexMap = new Map(
    toSort.map((item, index) => [item[idProperty], index])
  );

  const sortedIndexes = sortedItems
    .map((sortedItem) => itemIndexMap.get(sortedItem[idProperty]))
    .filter((index): index is number => index !== undefined);

  return headers.map((sHeader, index) => {
    if (index === headerIndex) {
      return {
        ...sHeader,
        sort: {
          active,
          order,
          sortable: sHeader.sort.sortable,
          sortedIndexes,
        },
      };
    } else {
      return sHeader;
    }
  });
};
