import {
  AdminNestingProjectData,
  NestingReport,
  NestingReportCSV,
  NestingReportJSON,
} from "../../service/api.dtos";
import { ErrorResponse, JsonServiceClient } from "@servicestack/client";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Loading } from "../../store/commonTypes";

let client = new JsonServiceClient(process.env.REACT_APP_API_BASE_URL);
type ReportState = {
  reportJson: AdminNestingProjectData[];
  reportJsonLoading: Loading;
  reportCsvLoading: Loading;
};

const initialState: ReportState = {
  reportJson: [],
  reportJsonLoading: "idle",
  reportCsvLoading: "idle",
};

export const fetchNestingReportCSV = createAsyncThunk(
  "reports/fetchNestingReports",
  async ({ from, to, userId }: NestingReport, thunkAPI) => {
    let contentDispositionHeader: string | null;
    client.responseFilter = function (r) {
      contentDispositionHeader = r.headers.get("content-disposition");
    };

    return await client
      .get(new NestingReportCSV({ from, to, userId }))
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = contentDispositionHeader!
          .toString()
          .match(/filename\*=UTF-8''(.+)/)!
          .slice(-1)[0]
          .split(";")[1]
          .trim();

        document.body.appendChild(a);
        a.click();
        a.remove();
        return blob;
      })
      .catch((error: ErrorResponse) => {
        //returns error into payload

        return thunkAPI.rejectWithValue(error);
      });
  }
);

export const fetchNestingReportJSON = createAsyncThunk(
  "reports/fetchNestingReportJson",
  async ({ from, to, userId }: NestingReport, thunkAPI) => {
    return await client
      .get(new NestingReportJSON({ from, to, userId }))
      .then((data) => {
        return data.nests;
      })
      .catch((error: ErrorResponse) => {
        //returns error into payload
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const nestingReportsSlice = createSlice({
  name: "nestingReports",
  initialState,
  reducers: {
    setJsonReports(state, action) {
      state.reportJson = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // GET: fetchNestingReportJson
      .addCase(fetchNestingReportJSON.pending, (state) => {
        state.reportJsonLoading = "pending";
      })
      .addCase(fetchNestingReportJSON.fulfilled, (state, action) => {
        state.reportJsonLoading = "succeeded";
        state.reportJson = action.payload as AdminNestingProjectData[];
      })
      .addCase(fetchNestingReportJSON.rejected, (state) => {
        state.reportJsonLoading = "failed";
      })
      // GET: fetchNestingReportCSV
      .addCase(fetchNestingReportCSV.pending, (state) => {
        state.reportCsvLoading = "pending";
      })
      .addCase(fetchNestingReportCSV.fulfilled, (state) => {
        state.reportCsvLoading = "succeeded";
      })
      .addCase(fetchNestingReportCSV.rejected, (state) => {
        state.reportCsvLoading = "failed";
      });
  },
});

export const { setJsonReports } = nestingReportsSlice.actions;

export default nestingReportsSlice.reducer;
